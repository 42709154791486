import {
  getClickWhatsAppEndpoint,
  getFilterOptionsEndpoint,
  getSearchLocationsEndpoint,
  getSearchResultsEndpoint,
  getSearchCompoundEndpoint,
  getSearchDeveloperEndpoint,
  getSearchAreaEndpoint,
  getPhoneLeadInfoEndPoint,
  getAvailablePropertyTypesEndpoint,
  getCompoundEndpoint,
  getPropertyEndpoint,
  getCompoundsCachedEndpoint,
  getCompoundsEndpoint,
  submitContactFormEndpoint,
  getTopAreasEndPoint,
  getPopularCompoundsEndpoint,
  getFavoritesEndPoint,
  getWatchListEndPoint,
  getAreaEndpoint,
  getRecommendedPropertiesEndpoint,
  getDeveloperEndpoint,
  getDevelopersEndpoint,
  getTrackActivityEndpoint,
  getStatisticsEndpoint,
  getAreasCachedEndpoint,
  submitPropertyMonitorFormEndpoint,
  getFooterLinksEndpoint,
  getAreasEndpoint,
  getRecentSearchEndpoint,
  getCompoundsSearchResultsEndpoint,
  getLaunchesAreasEndpoint,
  getRecommendedCompoundsEndpoint,
  getConfigVariablesEndpoint,
  getPropertyTypesEndpoint,
  getOffersEndpoint,
  getNewLandingPageEndpoint,
  resaleAdditionalDataEndpoint,
  getSahelCompoundsEndpoint,
  getSahelInfoEndpoint,
  getAreaFuzzyEndpoint,
  getPathwaysEndpoint,
  getCustomerLocationEndpoint,
  getOfferDevelopersEndpoint,
  getOfferAreasEndpoint,
  getNewOffersEndpoint,
  getOffersFilterOptionsEndpoint,
  getAgentVerification,
  getNeighborAreasEndpoint,
  getSubAreasEndpoint,
  getLaunchesSummaryEndpoint,
  getOnlineRecommendationsEndpoint,
  getAffordabilityEndpoint,
  autocompleteCompoundsEndpoint,
  getLaunchesSearchEndpoint,
  getLaunchesEndpoint,
  getTrendingProjectsEndpoint,
  favoritesEndpoint
} from './endpoints';
import { newApi, nextServerApi, oldApi } from './api.instance';
import { mapOptionsToNewForm } from '../../helpers/search-options/common';
import { Pagination } from './api.types';
import { LaunchesSearchRequestDTO } from '../../features/entity/launch/dtos/request.dtos';
import { LaunchesResponseDTO } from '../../features/entity/launch/dtos/response.dtos';

export const fetchAgentInfo = async (phone: number) => {
  const END_POINT = getAgentVerification();
  return newApi().post(END_POINT, { phoneNumber: `+2${phone}` });
};

export const clickWhatsapp = async (
  body: any,
  language: string | undefined
) => {
  const END_POINT = getClickWhatsAppEndpoint(language);
  return oldApi().post(END_POINT, body);
};

export const fetchSearchResults = async (body: any, language?: string) => {
  const END_POINT = getSearchResultsEndpoint(language);
  return oldApi().post(END_POINT, body);
};

export const fetchCompoundSearchResults = async (
  body: any,
  language?: string
) => {
  const END_POINT = getCompoundsSearchResultsEndpoint(language);
  return oldApi().post(END_POINT, body);
};

export const submitContactForm = async (body: any, language?: string) => {
  const END_POINT = submitContactFormEndpoint(language);
  return oldApi().post(END_POINT, body);
};

export const getFilterOptions = async (language?: string) => {
  const END_POINT = getFilterOptionsEndpoint(language);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => mapOptionsToNewForm(data));
};

export const getSearchLocations = async (
  term: string,
  language?: string,
  northcoast?: boolean,
  exclude?: string[]
): Promise<any> => {
  const END_POINT = getSearchLocationsEndpoint(
    term,
    language,
    northcoast,
    exclude
  );
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const autocompleteCompounds = async (
  language?: string,
  searchTerm?: string,
  by?: 'NAWY_UNLOCKED'
) => {
  const END_POINT = autocompleteCompoundsEndpoint({ searchTerm, by });
  return newApi({ ssrLocale: language })
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getAreaFuzzy = async (term: string, language?: string) => {
  const END_POINT = getAreaFuzzyEndpoint(term, language);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getSearchCompound = async (id: number, language?: string) => {
  const END_POINT = getSearchCompoundEndpoint(id, language);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getSearchDeveloper = async (id: number, language?: string) => {
  const END_POINT = getSearchDeveloperEndpoint(id, language);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getSearchArea = async (id: number, language?: string) => {
  const END_POINT = getSearchAreaEndpoint(id, language);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const phoneLeadsInfo = async (body: any, language?: string) => {
  const END_POINT = getPhoneLeadInfoEndPoint(language);
  return oldApi()
    .post(END_POINT, body)
    .then(({ data }) => data);
};

export const getTopAreas = async (
  pagination: Pagination,
  language?: string
) => {
  const END_POINT = getTopAreasEndPoint(pagination);
  return newApi({ ssrLocale: language })
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getCompound = async (
  slug: string,
  language?: string,
  detail?: string
) => {
  const END_POINT = getCompoundEndpoint(slug, language, detail);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getCompoundsCached = async (
  language?: string,
  moveNowOnly?: boolean
) => {
  const END_POINT = getCompoundsCachedEndpoint(language, moveNowOnly);
  return nextServerApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getAreasCached = async (
  language?: string,
  moveNowOnly?: boolean
) => {
  const END_POINT = getAreasCachedEndpoint(language, moveNowOnly);
  return nextServerApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getCompounds = async (
  language?: string,
  moveNowOnly?: boolean,
  body?: any
) => {
  const END_POINT = getCompoundsEndpoint(language, moveNowOnly, body);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getFooterLinks = async (language?: string) => {
  const END_POINT = getFooterLinksEndpoint();
  return newApi({ ssrLocale: language })
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getAvailablePropertyTypes = async (
  compoundId: number,
  language?: string
) => {
  const END_POINT = getAvailablePropertyTypesEndpoint(compoundId, language);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getProperty = async (
  slug: string,
  language?: string,
  detail?: string
) => {
  const END_POINT = getPropertyEndpoint(slug, language, detail);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getPopularCompounds = async (
  pagination: Pagination,
  language?: string
) => {
  const END_POINT = getPopularCompoundsEndpoint(pagination);
  return newApi({ ssrLocale: language })
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getRecentSearch = async (language?: string) => {
  const END_POINT = await getRecentSearchEndpoint(language);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getArea = async (slug: string, language?: string) => {
  const END_POINT = getAreaEndpoint(slug, language);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getDeveloper = async (slug: string, language?: string) => {
  const END_POINT = getDeveloperEndpoint(slug, language);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getDevelopers = async (
  with_summary?: boolean,
  language?: string
) => {
  const END_POINT = getDevelopersEndpoint(with_summary, language);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getAreas = async (with_summary?: boolean, language?: string) => {
  const END_POINT = getAreasEndpoint(with_summary, language);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getOnlineRecommendations = async (language?: string) => {
  const END_POINT = await getOnlineRecommendationsEndpoint();
  return newApi({ ssrLocale: language })
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getRecommendedProperties = async (
  language?: string,
  propertyID?: number | number[]
) => {
  const END_POINT = await getRecommendedPropertiesEndpoint(
    language,
    propertyID
  );
  return (await oldApi().get(END_POINT)).data;
};

export const trackActivity = async (body: any, language?: string) => {
  const END_POINT = getTrackActivityEndpoint(language);
  return oldApi()
    .post(END_POINT, body)
    .then(({ data }) => data);
};
export const getStatistics = async (language?: string) => {
  const END_POINT = getStatisticsEndpoint(language);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getNewLandingPage = async (id: string) => {
  const END_POINT = getNewLandingPageEndpoint(id);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const submitPropertyMonitorForm = async (
  body: any,
  language?: string
) => {
  const END_POINT = submitPropertyMonitorFormEndpoint(language);
  return oldApi()
    .post(END_POINT, body)
    .then(({ data }) => data);
};

export const getRecommendedCompounds = async (
  language?: string,
  id?: number
) => {
  const END_POINT = getRecommendedCompoundsEndpoint(language, id);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getLaunches = async (
  query: LaunchesSearchRequestDTO,
  locale?: string
): Promise<LaunchesResponseDTO> => {
  const END_POINT = getLaunchesEndpoint(query);
  return newApi({ ssrLocale: locale })
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getLaunchesSearch = async (
  language?: string,
  body?: any,
  summary?: string
) => {
  const END_POINT = getLaunchesSearchEndpoint(language, summary);
  return oldApi()
    .post(END_POINT, body)
    .then(({ data }) => data);
};

export const getLaunchesSummary = async (language?: string) => {
  const END_POINT = getLaunchesSummaryEndpoint();
  return newApi({ ssrLocale: language })
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getLaunchesAreas = async (language?: string) => {
  const END_POINT = getLaunchesAreasEndpoint(language);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getTrendingProjects = async (language?: string) => {
  const END_POINT = getTrendingProjectsEndpoint();
  return newApi({ ssrLocale: language })
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getConfigVariables = async () => {
  const END_POINT = getConfigVariablesEndpoint();
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getPropertyTypes = async (language?: string) => {
  const END_POINT = getPropertyTypesEndpoint(language);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getOffers = async (params: {}, language?: string) => {
  const END_POINT = getOffersEndpoint(language);
  return oldApi()
    .get(END_POINT, { params })
    .then(({ data }) => data);
};

export const getNewOffers = async (body: any, language?: string) => {
  const END_POINT = getNewOffersEndpoint(language);
  return oldApi()
    .post(END_POINT, body)
    .then(({ data }) => data);
};

export const addResaleData = async (body: any) => {
  const END_POINT = resaleAdditionalDataEndpoint();
  return oldApi().post(END_POINT, body);
};
export const getSahelCompounds = async (
  body: any,
  language?: string,
  area_id?: number
) => {
  const END_POINT = getSahelCompoundsEndpoint(language, area_id);
  return oldApi()
    .post(END_POINT, body)
    .then(({ data }) => data);
};

export const getSahelInfo = async (language?: string) => {
  const END_POINT = getSahelInfoEndpoint(language);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getPathways = async (type: string, language?: string) => {
  const END_POINT = getPathwaysEndpoint(type);
  return newApi({ ssrLocale: language })
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getCustomerLocation = async () => {
  const END_POINT = getCustomerLocationEndpoint();
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data)
    .catch(() => false);
};

export const getOffersFilterOptions = async (language?: string) => {
  const END_POINT = getOffersFilterOptionsEndpoint(language);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getOffersDevelopers = async (language?: string) => {
  const END_POINT = getOfferDevelopersEndpoint(language);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getOffersAreas = async (language?: string) => {
  const END_POINT = getOfferAreasEndpoint(language);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getNeighborAreas = async (body: any, language?: string) => {
  const END_POINT = getNeighborAreasEndpoint(language);
  return oldApi()
    .post(END_POINT, body)
    .then(({ data }) => data);
};

export const getSubAreas = async (body: any, language?: string) => {
  const END_POINT = getSubAreasEndpoint(language);
  return oldApi()
    .post(END_POINT, body)
    .then(({ data }) => data);
};

export const getAffordability = async (body: any) => {
  const END_POINT = getAffordabilityEndpoint();
  return newApi()
    .post(END_POINT, body)
    .then(({ data }) => data);
};

export const getFavoritesIds = async (): Promise<
  { id: number; type: 'Property' | 'Compound' }[]
> => {
  const END_POINT = favoritesEndpoint();
  return newApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const getFavorites = async (clientID: string, language?: string) => {
  const END_POINT = getFavoritesEndPoint(clientID, language);
  return oldApi()
    .get(END_POINT)
    .then(({ data }) => data);
};

export const updateFavorites = async (
  id: number,
  type: 'Property' | 'Compound',
  action: 'add' | 'remove'
): Promise<void> => {
  const END_POINT = favoritesEndpoint();
  await newApi()
    .post(END_POINT, { id, type, action })
    .catch(error => {});
};
