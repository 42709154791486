import Cookies from 'universal-cookie';
import { objToQuery, objToQueryWithoutCookies } from '../../helpers/common';
import { getTrackerClientId } from '../analytics';
import { Pagination } from './api.types';
import { LaunchesSearchRequestDTO } from '../../features/entity/launch/dtos/request.dtos';

const cookies = new Cookies();
const clientId = cookies.get('clientID');

export const getClickWhatsAppEndpoint = (language?: string): string => {
  const query = { ...(language && { language }) };
  return `/click_whatsapp?${objToQuery(query)}`;
};

export const getSearchResultsEndpoint = (language?: string) => {
  const query = { ...(language && { language }), client_id: clientId };
  return `/properties/search?${objToQuery(query)}`;
};

export const getCompoundsSearchResultsEndpoint = (language?: string) => {
  const query = { ...(language && { language }), client_id: clientId };
  return `/properties/compound_page_search?${objToQuery(query)}`;
};

export const getFilterOptionsEndpoint = (language?: string) => {
  const query = { ...(language && { language }) };
  return `/properties/get_filter_options?${objToQuery(query)}`;
};

export const getSearchLocationsEndpoint = (
  term: string,
  language?: string,
  northcoast?: boolean,
  exclude?: string[]
) => {
  let query = {
    ...(language && { language }),
    ...(northcoast && { northcoast })
  };

  let excludeQuery = '';

  if (exclude && exclude.length > 0) {
    exclude.forEach(type => {
      excludeQuery += `&exclude_types[]=${type}`;
    });
  }

  return `/locations?${objToQuery(query)}&search_term=${term}${excludeQuery}`;
};

export const getAreaFuzzyEndpoint = (term: string, language?: string) => {
  let query = {
    ...(language && { language })
  };

  return `/areas/autocomplete?${objToQuery(query)}&search_term=${term}`;
};

export const getSearchCompoundEndpoint = (id: number, language?: string) => {
  const query = { ...(language && { language }) };
  return `/compounds/${id}?${objToQuery(query)}`;
};

export const getSearchDeveloperEndpoint = (id: number, language?: string) => {
  const query = { ...(language && { language }) };
  return `/developers/${id}?${objToQuery(query)}`;
};

export const getSearchAreaEndpoint = (id: number, language?: string) => {
  const query = { ...(language && { language }) };
  return `/areas/${id}?${objToQuery(query)}`;
};

export const getPhoneLeadInfoEndPoint = (language?: string): string => {
  const query = { ...(language && { language }) };
  return `/phone_leads_info?${objToQuery(query)}`;
};

export const getCompoundEndpoint = (
  slug: string,
  language?: string,
  detail?: string
) => {
  const query = {
    slug,
    ...(language && { language }),
    ...(detail && { detail: detail })
  };
  return `/compounds?${objToQuery(query)}`;
};

export const getAvailablePropertyTypesEndpoint = (
  compoundId: number,
  language?: string
): string => {
  const query = { ...(language && { language }) };
  return `/compounds/${compoundId}/get_available_property_types?${objToQuery(
    query
  )}`;
};

export const getPropertyEndpoint = (
  slug: string,
  language?: string,
  detail?: string
) => {
  const query = {
    slug,
    ...(language && { language }),
    ...(detail && { detail: detail })
  };
  return `/properties?${objToQuery(query)}`;
};

export const getCompoundsCachedEndpoint = (
  language?: string,
  moveNowOnly?: boolean
) => {
  const query = { ...(language && { language, moveNowOnly }) };
  return `/api/getCompoundsCached?${objToQuery(query)}`;
};
export const getAreasCachedEndpoint = (
  language?: string,
  moveNowOnly?: boolean
) => {
  const query = { ...(language && { language, moveNowOnly }) };
  return `/api/getAreasCached?${objToQuery(query)}`;
};

export const getCompoundsEndpoint = (
  language?: string,
  moveNowOnly?: boolean,
  body?: any
) => {
  const query = { ...(language && { language, moveNowOnly, ...body }) };
  return `/compounds?${objToQuery(query)}`;
};

export const getFooterLinksEndpoint = () => {
  return `v1/footer-links`;
};

export const submitContactFormEndpoint = (language?: string) => {
  const query = { ...(language && { language }) };
  return `/submit_form?${objToQuery(query)}`;
};

export const getRecentSearchEndpoint = async (language?: string) => {
  const client_id = await getTrackerClientId();
  const query = {
    client_id,
    ...(language && { language }),
    max_results_size: 3
  };
  return `/recent_searches/?${objToQuery(query)}`;
};

export const getAreaEndpoint = (slug: string, language?: string) => {
  const query = { name: slug, ...(language && { language }) };
  return `/areas?${objToQuery(query)}`;
};

export const getDeveloperEndpoint = (slug: string, language?: string) => {
  const query = { slug, ...(language && { language }) };
  return `/developers?${objToQuery(query)}`;
};

export const getDevelopersEndpoint = (
  with_summary?: boolean,
  language?: string
) => {
  const query = {
    ...(with_summary && { with_summary: with_summary }),
    ...(language && { language })
  };
  return `/developers?${objToQuery(query)}`;
};

export const getAreasEndpoint = (with_summary?: boolean, language?: string) => {
  const query = {
    ...(with_summary && { with_summary: with_summary }),
    ...(language && { language })
  };
  return `/areas?${objToQuery(query)}`;
};

export const getTrackActivityEndpoint = (language?: string) => {
  const query = { ...(language && { language }) };
  return `/track_activity?${objToQuery(query)}`;
};

export const getStatisticsEndpoint = (language?: string) => {
  const query = { ...(language && { language }) };
  return `/properties/get_statistics?${objToQuery(query)}`;
};

export const getNewLandingPageEndpoint = (id: string) => {
  return `/landing_pages/${id}/new_landing`;
};

export const submitPropertyMonitorFormEndpoint = (language?: string) => {
  const query = { ...(language && { language }) };
  return `/property_monitor?${objToQuery(query)}`;
};

export const getLaunchesSearchEndpoint = (
  language?: string,
  summary?: string
) => {
  const query = {
    ...(language && { language }),
    ...(summary && { summary })
  };
  return `/launches/search?${objToQuery(query)}`;
};

export const getLaunchesEndpoint = (query: LaunchesSearchRequestDTO) => {
  const baseUrl = `v1/launches?page=${query.pagination.page}&pageSize=${query.pagination.pageSize}`;

  const additionalParams: string[] = [];

  if (query.searchTerm) {
    additionalParams.push(`searchTerm=${query.searchTerm}`);
  }

  if (query.areaIds?.length) {
    additionalParams.push(...query.areaIds.map(areaId => `areaIds=${areaId}`));
  }

  return additionalParams.length
    ? `${baseUrl}&${additionalParams.join('&')}`
    : baseUrl;
};

export const getLaunchesSummaryEndpoint = () => {
  return 'v1/launches/summary';
};

export const getLaunchesAreasEndpoint = (language?: string) => {
  const query = { ...(language && { language }) };
  return `/launches/areas?${objToQuery(query)}`;
};

export const getTrendingProjectsEndpoint = () => {
  return `v1/launches/trending`;
};

export const getConfigVariablesEndpoint = () => {
  return `/configration_variables`;
};

export const getPropertyTypesEndpoint = (language?: string) => {
  const query = { ...(language && { language }) };
  return `/property_types?${objToQuery(query)}`;
};

export const getOffersEndpoint = (language?: string) => {
  const query = { ...(language && { language }), show_discount_offers: true };
  return `/offers?${objToQuery(query)}`;
};

export const getNewOffersEndpoint = (language?: string) => {
  const query = { ...(language && { language }) };
  return `/offers/compounds_view?${objToQuery(query)}`;
};

export const resaleAdditionalDataEndpoint = () => {
  return `/resale_form_additional_data`;
};
export const getSahelCompoundsEndpoint = (
  language?: string,
  area_id?: number
) => {
  const query = { ...(language && { language }), ...(area_id && { area_id }) };

  return `/compounds/sahel_search?${objToQuery(query)}`;
};

export const getSahelInfoEndpoint = (language?: string) => {
  const query = { ...(language && { language }) };

  return `/areas/northcoast_map_info?${objToQuery(query)}`;
};

export const getCustomerLocationEndpoint = () => {
  return `/customers/check_location`;
};

export const getOffersFilterOptionsEndpoint = (language?: string) => {
  const query = { ...(language && { language }) };

  return `/offers/filters_options?language=${objToQuery(query)}`;
};

export const getOfferDevelopersEndpoint = (language?: string) => {
  const query = { ...(language && { language }) };

  return `/offers/developers_list?${objToQuery(query)}`;
};

export const getOfferAreasEndpoint = (language?: string) => {
  const query = {
    ...(language && { language })
  };

  return `/offers/areas_list?${objToQuery(query)}`;
};

export const getAgentVerification = () => {
  return `/v1/agents-verification`;
};

export const getNeighborAreasEndpoint = (language?: string) => {
  const query = {
    ...(language && { language })
  };
  return `/areas/get_neighbor_areas?${objToQuery(query)}`;
};

export const getSubAreasEndpoint = (language?: string) => {
  const query = {
    ...(language && { language })
  };
  return `/areas/get_subareas?${objToQuery(query)}`;
};

export const getAffordabilityEndpoint = () => {
  return `/v1/affordability/payment-ranges`;
};
