import { AnyAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import {
  addCompoundToFavorites,
  addPropertyToFavorites,
  removeCompoundFromFavorites,
  removePropertyFromFavorites
} from '../redux/slices/favoriteSlice';
import {
  getFavorites,
  getFavoritesIds,
  updateFavorites
} from '../new-lib/apis';
import { EntityTypes } from '../new-lib/constants/common';
import { trackEvent } from '../new-lib/remarketing';

interface WatchListPayload {
  client_id: string;
  entity_id: number;
}

export const updateFavoritesInWatchList = async (
  isFavorite: boolean,
  entityType: EntityTypes,
  payload: WatchListPayload
): Promise<void> => {
  if (entityType === 'property' || entityType === 'compound') {
    await updateFavorites(
      payload.entity_id,
      entityType === 'property' ? 'Property' : 'Compound',
      isFavorite ? 'remove' : 'add'
    );
  }
};

export const updateFavoritesInStore = (
  isFavorite: boolean,
  entityType: EntityTypes,
  dispatch: Dispatch<AnyAction>,
  payload: number
) => {
  if (isFavorite) {
    entityType == 'property'
      ? dispatch(removePropertyFromFavorites(payload))
      : dispatch(removeCompoundFromFavorites(payload));
  } else {
    trackEvent.initiateCheckout({
      listingIds: [(payload as any).id],
      listingPrice: (payload as any).min_price
    });
    entityType == 'property'
      ? dispatch(addPropertyToFavorites(payload))
      : dispatch(addCompoundToFavorites(payload));
  }
};

export const checkIsFavorite = (entities: any[], entityID: number) => {
  return entities.find(p => p.id == entityID) !== undefined;
};

export const handleClickTheFavorite = (
  isFavorite: boolean,
  entityType: EntityTypes,
  dispatch: Dispatch<AnyAction>,
  payload: WatchListPayload
) => {
  updateFavoritesInWatchList(isFavorite, entityType, payload);
  updateFavoritesInStore(isFavorite, entityType, dispatch, payload.entity_id);
};

export const getUserFavorites = async () => {
  return getFavoritesIds().then(favorites => ({
    properties: favorites
      .filter(favorite => favorite.type === 'Property')
      .map(favorite => favorite.id),
    compounds: favorites
      .filter(favorite => favorite.type === 'Compound')
      .map(favorite => favorite.id)
  }));
};

export const getFavoritesData = async (clientId: string, language?: string) => {
  const favorites: any[] = await getFavorites(clientId, language);

  const compounds = favorites
    .filter(favorite => favorite.type === 'Compound')
    .map(favorite => favorite.data);
  const properties = favorites
    .filter(favorite => favorite.type === 'Property')
    .map(favorite => favorite.data);

  return {
    compounds,
    properties
  };
};
