import Router from 'next/router';
import { getPageLocation } from '../helpers/common';
import { EntityTypes } from './constants/common';
import { SearchFiltersInterface } from '../reducers/search-filters/reducer';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { generateRandomId } from './experiments';
import mixpanel from 'mixpanel-browser';

const HOST = process.env.NEXT_PUBLIC_HOST;
const GA4_ID = process.env.NEXT_PUBLIC_GA4_ID;
const MIXPANEL_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;

const experimentVariant = new Cookies().get('_ab');

export const initConsent = async (isEUUser: boolean | undefined) => {
  const userConsent = localStorage.getItem('userConsent');
  if (userConsent != null) {
    return;
  } else {
    if (isEUUser) {
      wrapper('gtag', () => {
        gtag('consent', 'default', {
          ad_storage: 'denied',
          analytics_storage: 'denied'
        });
      });
      localStorage.setItem('userConsent', 'false');
    } else {
      wrapper('gtag', () => {
        gtag('consent', 'default', {
          ad_storage: 'granted',
          analytics_storage: 'granted'
        });
      });
      localStorage.setItem('userConsent', 'true');
    }
  }
};

export const configureGA4 = (customData: {}) => {
  wrapper('gtag', () => {
    gtag('config', GA4_ID!, {
      ...customData,
      landing_page: getPageLocation(Router.pathname),
      ab_variant: experimentVariant,
      page: getPageLocation(Router.pathname)
    });
  });
};

export const configureMixpanel = (userId: string, customParams: any) => {
  wrapper('mixpanel', () => {
    mixpanel.init(`${MIXPANEL_TOKEN}`, {
      debug: !HOST!.includes('nawy.com'),
      persistence: 'localStorage',
      api_host: `${HOST}/mp`
    });
    mixpanel.track_pageview({
      landing_page: getPageLocation(Router.pathname),
      page: getPageLocation(Router.pathname),
      ...customParams
    });
    mixpanel.identify(userId);
    mixpanel.register({
      landing_page: getPageLocation(Router.pathname),
      ab_variant: experimentVariant,
      ...customParams
    });
  });
};

export const identifyMoengage = (userId: string, locale: string) => {
  wrapper('moengage', () => {
    window.Moengage?.add_unique_user_id(userId);
    window.Moengage?.add_user_name(userId);
    window.Moengage?.add_user_attribute('locale', locale);
  });
};

export const identifyTiktokUser = (userId: string) => {
  wrapper('ttq', () => {
    window.ttq.identify({
      external_id: userId // string. Any unique identifier, such as loyalty membership IDs, user IDs, and external cookie IDs.It must be hashed with SHA-256 on the client side.
    });
  });
};
export const getTrackerClientId = async () => {
  const clientId = new Cookies().get('clientID');

  if (clientId) {
    return new Promise((resolve, reject) => {
      resolve(clientId);
    });
  }

  return axios
    .get('https://www.googletagmanager.com/gtag/js')
    .then(() => {
      // Get the tracker client Id
      return new Promise((resolve, reject) => {
        gtag('get', GA4_ID!, 'client_id', clientId => {
          if (clientId) {
            resolve(clientId);
          } else {
            reject('Client ID is not available');
          }
        });
      });
    })
    .catch(() => {
      return new Promise((resolve, reject) => {
        const clientId = generateRandomId();
        new Cookies().set('clientID', clientId);
        resolve(clientId);
      });
    });
};

export const logMixpanelPageView = () => {
  wrapper('mixpanel', () => {
    mixpanel.track_pageview({
      page: getPageLocation(Router.pathname)
    });
  });
};

const wrapper = (
  key: 'mixpanel' | 'gtag' | 'moengage' | 'fbq' | 'ttq',
  func: () => void
) => {
  const onLoaded = () => {
    if (!func) {
      return;
    }
    try {
      func();
      window.removeEventListener(key, onLoaded); // Cleanup
    } catch {}
  };

  switch (key) {
    case 'ttq':
      if (typeof window.ttq.identify === 'function') {
        onLoaded();
      } else {
        window.addEventListener(`${key}Loaded`, onLoaded);
      }
      break;
    case 'fbq':
      if (typeof fbq === 'function') {
        onLoaded();
      } else {
        window.addEventListener(`${key}Loaded`, onLoaded);
      }
      break;
    case 'gtag':
      if (typeof gtag === 'function') {
        onLoaded();
      } else {
        window.addEventListener(`${key}Loaded`, onLoaded);
      }
      break;
    case 'mixpanel':
      // if (typeof mixpanel?.track === 'function') {
      //   onLoaded();
      // } else {
      //   window.addEventListener(`${key}Loaded`, onLoaded);
      // }
      break;
    case 'moengage':
      if (typeof window.Moengage?.track_event === 'function') {
        onLoaded();
      } else {
        window.addEventListener(`${key}Loaded`, onLoaded);
      }
      break;
  }
};

const trackMixpanelEvent = (eventName: string, eventParams: {}) => {
  wrapper('mixpanel', () => {
    mixpanel.track(eventName, eventParams);
  });
};
const trackMoengageEvent = (eventName: string, eventParams: any) => {
  wrapper('mixpanel', () => {
    window.Moengage.track_event(eventName, eventParams);
  });
};

export const trackExperiment = (
  experimentName: string,
  variantName: string
) => {
  wrapper('mixpanel', () => {
    mixpanel.track('$experiment_started', {
      experiment_name: experimentName,
      ab_variant: variantName,
      page: getPageLocation(Router.pathname)
    });
  });
  wrapper('gtag', () => {
    gtag('event', 'Experiment', {
      experiment_name: experimentName,
      ab_variant: variantName,
      page: getPageLocation(Router.pathname)
    });
  });
};

type ExtraClickParams = {
  recommended?: boolean;
  recommended_type?: 'Financing' | 'General';
  prop_id?: number;
  prop_name?: string;
  area_id?: number;
  area_name?: string;
  areas?: string[] | null;
  count_areas?: number;
  cmpnd_id?: number;
  cmpnd_name?: string;
  cmpnds?: string[] | null;
  dev_id?: number;
  dev_name?: string;
  count_devs?: number;
  lnch_id?: number;
  lnch_name?: string;
  index?: number;
  pathway_text?: string;
  page_number?: number;
  page_no?: number;
  org_id?: number;
  download_platform?: 'ios' | 'android';
  sahel_distance_checked?: boolean;
  offers_filters?: {
    type:
      | 'nawy_exclusive'
      | 'areas_search'
      | 'developers_search'
      | 'offer_types';
    action?: 'add' | 'remove';
    area_id?: number;
    developer_id?: number;
    offer_type?: string;
    nawy_exclusive?: boolean;
  };
  valid_agent?: boolean;
  entity_type?: EntityTypes;
  amenity_name?: string;
  nearby_area_id?: number;
  nearby_area_name?: string;
};

type ExtraContactParams = {
  recommended?: boolean;
  entity_type?: EntityTypes;
  recommended_type?: 'Financing' | 'General';
  cmpnd_id?: number;
  cmpnd_name?: string;
  area_id?: number;
  area_name?: string;
  prop_id?: number;
  prop_name?: string;
  dev_id?: number;
  dev_name?: string;
  lnch_id?: number;
  lnch_name?: string;
  page_no?: number;
  index?: number;
  forecast_available?: boolean;
  ab_variant?: string;
  org_id?: number;
  prop_type?: string;
  finishing_type?: string;
};

export const logEvent = {
  click: (
    eventName: string,
    eventLocation: string,
    featureName: string,
    extraParams?: ExtraClickParams
  ) => {
    const params = {
      feature: featureName,
      behaviour_group: 'click',
      event_location: eventLocation,
      ...extraParams,
      ...(Router.query.recommended && {
        recommendedClick: true
      }),
      page: getPageLocation(Router.pathname),
      org_id: extraParams?.org_id ?? 1
    };

    wrapper('gtag', () => {
      gtag('event', eventName, { send_to: GA4_ID, ...params });
    });
    trackMixpanelEvent(eventName, params);
    trackMoengageEvent(eventName, params);
  },
  search: (
    eventName: string,
    eventLocation: string,
    featureName: string,
    searchFiltersState: SearchFiltersInterface,
    extraParams?: any
  ) => {
    const params = {
      feature: featureName,
      behaviour_group: 'search',
      event_location: eventLocation,
      ...searchFiltersState.searchFilters,
      ...extraParams,
      ...(Router.query.recommended && {
        recommendedClick: true
      }),
      page: getPageLocation(Router.pathname),
      org_id: extraParams?.org_id ?? 1
    };

    // const queryMapping = createSearchQueryMapping(searchFiltersState);
    wrapper('gtag', () => {
      gtag('event', eventName, {
        send_to: GA4_ID,
        ...params
      });
    });
    trackMixpanelEvent(eventName, params);
    trackMoengageEvent(eventName, params);
  },
  contact: (
    eventName: string,
    eventLocation: string,
    eventId: any,
    extraParams?: ExtraContactParams
  ) => {
    const params = {
      contact_method: eventName,
      feature: 'contact',
      behaviour_group: 'contact',
      event_location: eventLocation,
      event_id: eventId,
      ...extraParams,
      page: getPageLocation(Router.pathname),
      org_id: extraParams?.org_id ?? 1
    };

    wrapper('gtag', () => {
      gtag('event', 'contact', {
        send_to: GA4_ID,
        ...params
      });
    });
    trackMixpanelEvent('contact', params);
    trackMoengageEvent('contact', params);
  }
};

export const getFirstPathSegment = (path: string) => {
  if (path === '/') {
    return 'Home';
  }
  const regex = /^\/([^\/]+)/;

  const firstPathSegment = path.match(regex)![1];

  return (
    firstPathSegment?.charAt(0)?.toUpperCase() + firstPathSegment?.slice(1)
  );
};
