import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import BedroomIcon from '../../svg-icons/BedroomIcon';
import BathIcon from '../../svg-icons/BathIcon';
import AreaIcon from '../../svg-icons/AreaIcon';

interface PropertyDetailsProps {
  entityData: {
    number_of_bedrooms: number;
    number_of_bathrooms: number;
    min_unit_area: number;
    min_ready_by: number | string;
    isNawyNow?: boolean;
  };
}

const PropertyDetails = ({ entityData }: PropertyDetailsProps) => {
  const { t } = useTranslation(['common', 'entity']);

  return (
    <Container isNawyNow={entityData.isNawyNow}>
      {entityData.number_of_bedrooms ? (
        <div>
          <p>{entityData.number_of_bedrooms}</p>
          <BedroomIcon />
        </div>
      ) : (
        ''
      )}
      {entityData.number_of_bathrooms ? (
        <div>
          <p>{entityData.number_of_bathrooms}</p>
          <BathIcon />
        </div>
      ) : (
        ''
      )}

      <div className="type_area">
        {entityData.number_of_bedrooms > 0 ||
        entityData.number_of_bathrooms > 0 ? (
          <div className="separator"></div>
        ) : (
          <></>
        )}
        {entityData.min_unit_area && entityData.min_unit_area > 0 ? (
          <div className="area-details">
            <AreaIcon />
            <span className="text-1">
              {entityData.min_unit_area}&nbsp;
              {t('m²')}
            </span>
          </div>
        ) : null}
        {!entityData.number_of_bedrooms &&
        !entityData.number_of_bathrooms &&
        !entityData.min_unit_area &&
        !entityData.min_ready_by ? (
          <span className="fallback-info text-2">
            {t('details_tba', { ns: 'entity' })}
          </span>
        ) : null}
      </div>
    </Container>
  );
};

const Container = styled.div<any>`
  margin: 16px 0;
  display: flex;
  gap: 0px 8px;
  > div {
    width: 30px;
    min-height: 27px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: ${props => (props.isNawyNow ? '#0000001a' : '#EFF1F4')};
    padding: 1px 3px;
    overflow: hidden;
    position: relative;
    p {
      color: ${props =>
        props.isNawyNow
          ? `${props.theme.light.colors.white}`
          : `${props.theme.light.colors.black}`};
      font-family: materialMedium;
      font-size: ${props => props.theme.font.sizes.xxs};
      margin: 0px !important;
      padding: 0px !important;
      position: absolute;
      top: 1px;
      inset-inline-start: 1px;
    }
    svg {
      position: absolute;
      bottom: 4px;
      path {
        fill: ${props =>
          props.isNawyNow ? `${props.theme.light.colors.white}` : ''};
      }
      rect {
        stroke: ${props =>
          props.isNawyNow ? `${props.theme.light.colors.white}` : ''};
      }
    }
  }

  .type_area {
    background: none;
    width: fit-content;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;

    .area-details {
      display: flex;
      align-items: center;
    }

    .separator {
      height: 100%;
      width: 1px;
      margin-inline-end: 8px;
      background-color: ${props => props.theme.light.borderColors.primary};
    }
    svg {
      position: static;
      margin-inline-end: 5px;
    }

    span.text-1 {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-family: materialMedium;
      font-size: ${props => props.theme.font.sizes.l};
      color: ${props =>
        props.isNawyNow
          ? `${props.theme.light.colors.white}`
          : `${props.theme.light.colors.black}`};
    }

    span.fallback-info {
      margin-left: -3px;
    }
  }
`;

export default PropertyDetails;
