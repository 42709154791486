import { useState, useMemo, createContext, useEffect } from 'react';
import { SearchFiltersInterface } from '../../../reducers/search-filters/reducer';
import {
  SearchFiltersActionTypes,
  SearchFiltersActions
} from '../../../reducers/search-filters/actions';
import { filterOptions } from '../../../new-lib/types/search-filters';
import useSearchFilters from '../../../hooks/SearchFilters';
import { useRouter } from 'next/router';
import { initializeSearchFiltersFromQuery } from '../../../helpers/search-filters';
import { containsAnyWord } from '../../../helpers/common';

export interface SearchContextInterface {
  filterOptions: filterOptions;
  showFilters: boolean;
  setShowFilters: React.Dispatch<React.SetStateAction<boolean>>;
  searchFiltersState: SearchFiltersInterface;
  searchFiltersDispatch: React.Dispatch<SearchFiltersActions>;
  showUniversalSearch: boolean;
  setShowUniversalSearch: React.Dispatch<React.SetStateAction<boolean>>;
  searching: boolean;
  setSearching: React.Dispatch<React.SetStateAction<boolean>>;
  appliedFiltersCount: number;
  maxDeliveryYear: number;
}

export const SearchContext = createContext<SearchContextInterface | boolean>(
  false
);

interface SearchProviderProps {
  filterOptions: any;
  children: React.ReactNode;
}

const pathsToSetFiltersFromQuery = [
  '/search',
  '/compound',
  '/area',
  '/developer',
  '/favorites',
  '/nawy-now',
  '/sahel-map',
  '/affordability-results'
];
const SearchProvider: React.FC<SearchProviderProps> = ({
  filterOptions,
  children
}) => {
  const router = useRouter();
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [searching, setSearching] = useState<boolean>(false);
  const [showUniversalSearch, setShowUniversalSearch] =
    useState<boolean>(false);
  const { searchFiltersState, dispatch: searchFiltersDispatch } =
    useSearchFilters();

  const appliedFiltersCount = [
    searchFiltersState.searchFilters.bathrooms.length > 0,
    searchFiltersState.searchFilters.bedrooms.length > 0,
    searchFiltersState.searchFilters.delivery_date != undefined &&
      !router.asPath.includes('/nawy-now'),
    searchFiltersState.searchFilters.down_payment != undefined,
    searchFiltersState.searchFilters.finishing.length > 0,
    searchFiltersState.searchFilters.installment_years.length > 0,
    searchFiltersState.searchFilters.max_price != undefined,
    searchFiltersState.searchFilters.max_unit_area != undefined,
    searchFiltersState.searchFilters.min_price != undefined,
    searchFiltersState.searchFilters.min_unit_area != undefined,
    searchFiltersState.searchFilters.monthly_installments != undefined,
    searchFiltersState.searchFilters.property_types.length > 0,
    searchFiltersState.searchFilters.sale_type.length > 0 &&
      router.asPath.includes('/search'),
    searchFiltersState.searchFilters.amenities.length > 0 &&
      router.asPath.includes('/search'),
    searchFiltersState.searchFilters.has_garden,
    searchFiltersState.searchFilters.has_roof,
    searchFiltersState.searchFilters.nawy_now &&
      !router.asPath.includes('/nawy-now'),
    searchFiltersState.searchFilters.compounds.length > 0 &&
      router.asPath.includes('/nawy-now'),
    searchFiltersState.searchFilters.areas.length > 0 &&
      router.asPath.includes('/nawy-now')
  ].filter(filter => filter).length;

  useEffect(() => {
    // reset all filters at the starting point of the search journey
    if (router.asPath === '/')
      searchFiltersDispatch({
        type: SearchFiltersActionTypes.RESET
      });
  }, [router.asPath]);

  useEffect(() => {
    if (
      !containsAnyWord(router.asPath, pathsToSetFiltersFromQuery) ||
      Object.keys(router.query).length == 0
    )
      return;

    if (router.query)
      initializeSearchFiltersFromQuery(router.query, searchFiltersDispatch);
  }, [router]);

  const getMaxDeliveryYear = () => {
    return new Date(
      filterOptions.delivery_date[filterOptions.delivery_date.length - 1]
        .value as any
    ).getFullYear();
  };

  useEffect(() => {
    setSearching(false);
  }, [router.asPath]);

  const memoizedContext = useMemo(
    () => ({
      filterOptions,
      showFilters,
      setShowFilters,
      searchFiltersState,
      searchFiltersDispatch,
      searching,
      setSearching,
      showUniversalSearch,
      setShowUniversalSearch,
      appliedFiltersCount,
      maxDeliveryYear: getMaxDeliveryYear()
    }),
    [
      filterOptions,
      showFilters,
      setShowFilters,
      searchFiltersState,
      searchFiltersDispatch,
      searching,
      setSearching,
      showUniversalSearch,
      setShowUniversalSearch,
      appliedFiltersCount,
      getMaxDeliveryYear
    ]
  );
  return (
    <SearchContext.Provider value={memoizedContext}>
      {children}
    </SearchContext.Provider>
  );
};

export default SearchProvider;
