import { useEffect, useRef, useState } from 'react';
import { useSearch } from '../../hooks/SearchInput';
import styled from 'styled-components';
import SearchInput from './SearchInput';
import { useRouter } from 'next/router';
import SearchDropdown from './SearchDropdown';
import { useSelector } from 'react-redux';
import useOnClickOutside from '../../../../hooks/ClickOutside';
import ButtonIcon from '../../../../new-components/ui/ButtonIcon';
import ThinArrowIcon from '../../../../new-components/svg-icons/ThinArrowIcon';
import SearchFilterBtn from './SearchFilterBtn';

interface SearchBarProps {
  selectedFilters?: string;
}

const SearchBar = ({ selectedFilters }: SearchBarProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const isMobile = useSelector((state: any) => state.app.isMobile);
  const router = useRouter();
  const { locale } = router;

  const {
    setSearchTerm,
    searchResults,
    isError,
    selectedSearches,
    handleSelect,
    appliedFilters,
    handleRemove,
    setShowUniversalSearch,
    setSearching,
    showFilters,
    setShowFilters
  } = useSearch({
    setDropdownOpen,
    searchInputLocation: 'UniversalSearch',
    selectedFilters
  });
  const dropdownRef = useRef(null);

  const closeMenu = () => {
    setShowUniversalSearch(false);
    setDropdownOpen(false);
    setSearching(false);
    document.body.style.position = 'unset';
  };

  useOnClickOutside(dropdownRef, dropdownRef, () => {
    if (!isMobile && dropdownOpen) {
      closeMenu();
    }
  });
  useEffect(() => {
    if (document && isMobile && (showFilters || dropdownOpen)) {
      document.body.style.position = 'fixed';
    } else {
      document.body.style.position = 'unset';
    }
  }, [showFilters, dropdownOpen]);

  useEffect(() => {
    if (document && isMobile) {
      document
        .querySelector('.app-wrapper')
        ?.classList.add('search-bar-included');
    }
    return () => {
      document
        .querySelector('.app-wrapper')
        ?.classList.remove('search-bar-included');
    };
  }, [isMobile]);

  return (
    <Container ref={dropdownRef} className={isMobile ? 'container-fluid' : ''}>
      <div className="search-filter-bar">
        {dropdownOpen && isMobile && (
          <ButtonIcon
            icon={
              <ThinArrowIcon
                stroke={'#5F606A'}
                styles={locale == 'ar' ? { transform: 'rotate(180deg)' } : {}}
              />
            }
            border="none"
            backgroundColor="transparent"
            color="none"
            onClick={closeMenu}
            containerClass="back-button"
          />
        )}
        <SearchInput
          dropdownOpen={dropdownOpen}
          setDropdownOpen={setDropdownOpen}
          setSearchTerm={setSearchTerm}
          selectedSearches={selectedSearches}
          handleRemove={handleRemove}
        />
        {isMobile &&
          router.asPath !== '/' &&
          !router.asPath.includes('/property') && (
            <SearchFilterBtn
              setShowFilters={setShowFilters}
              appliedFilters={appliedFilters}
            />
          )}
      </div>

      {dropdownOpen && (
        <SearchDropdown
          handleSelect={handleSelect}
          searchResults={searchResults}
          isError={isError}
          dropdownRef={dropdownRef}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  background-color: ${props => props.theme.light.colors.white};
  border-radius: 10px;
  position: relative;
  height: 100%;
  margin: 0;
  .search-filter-bar {
    display: flex;
    flex-direction: row;
    gap: 8px;
    height: 100%;
    align-items: center;
  }

  @media screen and (max-width: ${props =>
      props.theme.breakPoints.mobile.max}) {
    border-radius: 0;
    .search-filter-bar {
      padding: 16px 0;

      .back-button {
        border-radius: 50%;
        background-color: #f7f9fd;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
          svg {
            width: 8px;
          }
        }
      }
    }
  }
`;

export default SearchBar;
