import { logEvent } from '../../../new-lib/analytics';
import Header from './HeaderPresentation';
import MobileHeader from './MobileHeaderPresentation';

interface Props {
  forceMobile?: boolean;
  areas?: any;
  developers?: any;
}

const HeaderContainer = ({ forceMobile, areas, developers }: Props) => {
  const handleNavbarItemClick = (eventName: string, eventFeature: string) => {
    logEvent.click(eventName, 'header', eventFeature);
  };

  const MobileViewHeader: JSX.Element = (
    <MobileHeader
      handleNavbarItemClick={handleNavbarItemClick}
      areas={areas}
      developers={developers}
    />
  );
  const DesktopViewHeader: JSX.Element = (
    <Header handleNavbarItemClick={handleNavbarItemClick} />
  );

  return forceMobile ? MobileViewHeader : DesktopViewHeader;
};
export default HeaderContainer;
