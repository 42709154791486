import { createSlice } from '@reduxjs/toolkit';
import {
  Compound,
  MapMode,
  QuickFilter
} from '../../features/sahel-mapbox/types';

interface InitialState {
  info: any;
  selectedCompound: any;
  mode: MapMode;
  firstDestination: Compound | null;
  secondDestination: Compound | null;
  drawerOpen: boolean;
  quickFilter: QuickFilter | null;
  drawerPosition: number;
  showCalculateDistanceTooltip: boolean;
  sahelModal: boolean;
}
const initialState: InitialState = {
  info: null,
  selectedCompound: null,
  mode: MapMode.Search,
  firstDestination: null,
  secondDestination: null,
  drawerOpen: false,
  quickFilter: null,
  showCalculateDistanceTooltip: true,
  drawerPosition: 35,
  sahelModal: true
};


export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setInfo(state, action) {
      state.info = action.payload;
    },
    setSelectedCompound(state, action) {
      state.selectedCompound = action.payload;
    },
    setMapMode(state, action) {
      if (
        state.mode === MapMode.CalculateDistance &&
        action.payload !== MapMode.CalculateDistance
      ) {
        state.firstDestination = null;
        state.secondDestination = null;
      }

      state.mode = action.payload;
    },
    setFirstDestination(state, action) {
      state.firstDestination = action.payload;
    },
    setSecondDestination(state, action) {
      state.secondDestination = action.payload;
    },
    setDrawerOpen(state, action) {
      state.drawerOpen = action.payload;
    },
    setQuickFilter(state, action) {
      if (state.quickFilter === action.payload) {
        state.quickFilter = null;
      } else {
        state.quickFilter = action.payload;
      }
    },
    setDrawerPosition(state, action) {
      state.drawerPosition = action.payload;
    },
    setShowCalculateDistanceTooltip(state, action) {
      state.showCalculateDistanceTooltip = action.payload;
    },
    setModal(state, action) {
      state.sahelModal = action.payload;
    }
  }
});

export const {
  setInfo,
  setSelectedCompound,
  setMapMode,
  setFirstDestination,
  setSecondDestination,
  setDrawerOpen,
  setQuickFilter,
  setDrawerPosition,
  setShowCalculateDistanceTooltip,
  setModal
} = mapSlice.actions;

export default mapSlice.reducer;
