import {
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { SearchFiltersTypes } from '../../../reducers/search-filters/reducer';
import { useRouter } from 'next/router';

import {
  getSearchArea,
  getSearchCompound,
  getSearchDeveloper,
  getSearchLocations
} from '../../../new-lib/apis';
import { SearchFiltersActionTypes } from '../../../reducers/search-filters/actions';
import { updateQuery } from '../../../helpers/common';
import { logEvent } from '../../../new-lib/analytics';
import {
  CategoryTypes,
  SearchInputResult
} from '../components/search-bar/SearchLocationResults';
import { SearchSelection } from '../components/search-bar/SearchSelections';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import {
  SearchContext,
  SearchContextInterface
} from '../context/SearchContext';

interface SearchProps {
  setDropdownOpen: React.Dispatch<SetStateAction<boolean>>;
  searchInputLocation: string;
  selectedFilters?: string;
}

export const useSearch = ({
  setDropdownOpen,
  searchInputLocation
}: SearchProps) => {
  const {
    searchFiltersState,
    searchFiltersDispatch,
    showUniversalSearch,
    setShowUniversalSearch,
    appliedFiltersCount,
    setSearching,
    setShowFilters,
    showFilters
  } = useContext(SearchContext) as SearchContextInterface;
  const router = useRouter();
  const { locale, asPath } = router;
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDevelopers, setSelectedDevelopers] = useState<
    SearchInputResult[]
  >([]);
  const [selectedAreas, setSelectedAreas] = useState<SearchInputResult[]>([]);
  const [selectedCompounds, setSelectedCompounds] = useState<
    SearchInputResult[]
  >([]);
  const [selectedSearches, setSelectedSearches] =
    useState<SearchSelection | null>(null);

  const isMobile = useSelector((state: any) => state.app.isMobile);

  const {
    data: searchResults,
    isFetching,
    isError
  } = useQuery(
    ['searchLocations', searchTerm, locale],
    () => getSearchLocations(searchTerm, locale),
    {
      keepPreviousData: true,
      retry: false
    }
  );

  const compoundRoute = useCallback(
    async (compound: SearchInputResult) => {
      logEvent.search(
        'search_select',
        searchInputLocation,
        'search',
        searchFiltersState,
        {
          compound_id: compound.id
        }
      );
      return router.push(`/compound/${compound.slug}`);
    },
    [router]
  );

  const launchRoute = useCallback(
    async (launch: SearchInputResult) => {
      logEvent.search(
        'search_select',
        searchInputLocation,
        'search',
        searchFiltersState,
        {
          lnch_id: launch.id
        }
      );
      return router.push(`/compound/${launch.slug}`);
    },
    [router]
  );

  const updateSearchFilters = useCallback(
    (
      locationCategory:
        | SearchFiltersTypes.AREAS
        | SearchFiltersTypes.DEVELOPERS,
      ids: number[]
    ) => {
      setDropdownOpen(false);
      searchFiltersDispatch({
        type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_MULTISELECT,
        payload: {
          name: locationCategory,
          value: ids
        }
      });
      if (asPath != '/') {
        updateQuery(router, [
          {
            name: locationCategory,
            value: ids.toString()
          }
        ]);
      }
    },
    [router]
  );

  const handleSelect = useCallback(
    (selection: SearchInputResult) => {
      setSearchTerm('');
      if (selection.category == CategoryTypes.COMPOUND) {
        clearSearch(false);
        compoundRoute(selection).catch(err => err);
      } else if (selection.category == CategoryTypes.LAUNCH) {
        clearSearch(false);
        launchRoute(selection).catch(err => err);
      } else if (!asPath.includes('/search') && isMobile) {
        clearSearch(false);
        router.push({
          pathname: '/search',
          query: `${selection.category}s=${selection.id}`
        });
        logEvent.search(
          'search_select',
          searchInputLocation,
          'search',
          searchFiltersState,
          {
            [selection.category == 'area' ? 'area_id' : 'dev_id']: selection.id
          }
        );
      } else {
        clearSearch();
        updateSearchFilters(`${selection.category}s` as any, [selection.id]);
        logEvent.search(
          'search_select',
          searchInputLocation,
          'search',
          searchFiltersState,
          {
            [selection.category == 'area' ? 'area_id' : 'dev_id']: selection.id
          }
        );
        setSelectedSearches(selection as any);
      }
    },
    [router, compoundRoute, launchRoute]
  );

  const clearSearch = useCallback(
    (updateUrlQuery = true) => {
      searchFiltersDispatch({
        type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_MULTISELECT,
        payload: {
          name: SearchFiltersTypes.AREAS,
          value: []
        }
      });
      searchFiltersDispatch({
        type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_MULTISELECT,
        payload: {
          name: SearchFiltersTypes.DEVELOPERS,
          value: []
        }
      });
      if (updateUrlQuery) {
        updateQuery(router, undefined, [
          SearchFiltersTypes.AREAS,
          SearchFiltersTypes.DEVELOPERS
        ]);
      }
    },
    [router]
  );

  useEffect(() => {
    if (searchTerm !== '')
      logEvent.search(
        'search_query',
        searchInputLocation,
        'search',
        searchFiltersState,
        {
          search_query: searchTerm
        }
      );
  }, [searchTerm]);

  const getDevelopers = async () => {
    let developers = searchFiltersState.searchFilters.developers;
    const results = developers.map(id => {
      return getSearchDeveloper(id, locale);
    });
    let devArr: SearchInputResult[] = [];
    Promise.all(results)
      .then((res: any) => {
        res.forEach((developer: any) => {
          if (developer) {
            devArr.push({
              name: developer.name,
              id: developer.id,
              category: CategoryTypes.DEVELOPER
            });
          }
        });
        setSelectedDevelopers(devArr);
      })
      .catch(err => err);
  };

  const getAreas = async () => {
    let areas = searchFiltersState.searchFilters.areas;
    const results = areas.map(id => {
      return getSearchArea(id, locale);
    });
    let areasArray: SearchInputResult[] = [];
    Promise.all(results)
      .then((res: any) => {
        res.forEach((area: any) => {
          if (area) {
            areasArray.push({
              name: area.name,
              id: area.id,
              category: CategoryTypes.AREA
            });
          }
        });
        setSelectedAreas(areasArray);
      })
      .catch(err => err);
  };

  const getCompounds = async () => {
    let compounds = searchFiltersState.searchFilters.compounds;
    const results = compounds.map(id => {
      return getSearchCompound(id, locale);
    });
    let compoundsArray: SearchInputResult[] = [];
    Promise.all(results)
      .then((res: any) => {
        res.forEach((compound: any) => {
          if (compound) {
            compoundsArray.push({
              name: compound.name,
              id: compound.id,
              category: CategoryTypes.COMPOUND
            });
          }
        });
        setSelectedCompounds(compoundsArray);
      })
      .catch(err => err);
  };

  const removeSelected = useCallback(
    (
      newArr: number[],
      locationCategory:
        | SearchFiltersTypes.AREAS
        | SearchFiltersTypes.DEVELOPERS,
      isClearAll?: boolean
    ) => {
      searchFiltersDispatch({
        type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_MULTISELECT,
        payload: {
          name: locationCategory,
          value: newArr
        }
      });
      if (newArr.length > 0 && asPath != '/') {
        updateQuery(router, [
          {
            name: locationCategory,
            value: newArr.toString()
          }
        ]);
      } else if (!isClearAll) {
        updateQuery(router, undefined, [locationCategory]);
      }
    },
    [router]
  );

  const handleRemove = useCallback(
    (selection: SearchSelection) => {
      if (selection.category == 'developer') {
        let newArr = selectedDevelopers
          .filter(item => item.id !== selection.id)
          .map(item => item.id);

        removeSelected(newArr, SearchFiltersTypes.DEVELOPERS);
      } else {
        let newArr = selectedAreas
          .filter(item => item.id !== selection.id)
          .map(item => item.id);
        removeSelected(newArr, SearchFiltersTypes.AREAS);
      }
    },
    [removeSelected]
  );

  useEffect(() => {
    getAreas().catch(err => err);
  }, [searchFiltersState.searchFilters.areas]);
  useEffect(() => {
    getDevelopers().catch(err => err);
  }, [searchFiltersState.searchFilters.developers]);
  useEffect(() => {
    getCompounds().catch(err => err);
  }, [searchFiltersState.searchFilters.compounds]);

  useEffect(() => {
    if (asPath == '/') {
      searchFiltersDispatch({
        type: SearchFiltersActionTypes.RESET
      });
    }
    if (asPath != '/') {
      setShowUniversalSearch(false);
    }
  }, [asPath]);

  useEffect(() => {
    setDropdownOpen(false);
  }, [asPath]);
  useEffect(() => {
    if (asPath === '/') {
      showUniversalSearch ? setDropdownOpen(true) : setDropdownOpen(false);
    }
  }, [showUniversalSearch]);

  useEffect(() => {
    if (
      searchFiltersState.searchFilters.areas.length == 0 &&
      searchFiltersState.searchFilters.developers.length == 0 &&
      searchFiltersState.searchFilters.compounds.length == 0
    ) {
      setSelectedSearches(null);
    }
  }, [
    searchFiltersState,
    selectedAreas,
    selectedDevelopers,
    selectedCompounds
  ]);

  return {
    searchTerm,
    isLoading: isFetching,
    isError,
    setSearchTerm,
    searchResults,
    selectedSearches,
    clearSearch,
    handleSelect,
    handleRemove,
    appliedFilters: appliedFiltersCount,
    setShowUniversalSearch,
    setSearching,
    showFilters,
    setShowFilters
  };
};
