type PropsType = {
  styles?: object;
  fill?: string;
  stroke?: string;
  hoverFill?: string;
};

const InstagramIcon = ({ styles, fill, stroke, hoverFill }: PropsType) => {
  return (
    <svg
      className="instagramSvg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      style={styles}
      xmlns="http://www.w3.org/2000/svg"
    >
      <style>
        {`.instagramSvg:hover .fillPath {fill: ${hoverFill || '#1E4164'};}`}
      </style>
      <path
        className="fillPath"
        d="M15.2001 29.8113C23.2635 29.8113 29.8001 23.2747 29.8001 15.2113C29.8001 7.14797 23.2635 0.611328 15.2001 0.611328C7.13674 0.611328 0.600098 7.14797 0.600098 15.2113C0.600098 23.2747 7.13674 29.8113 15.2001 29.8113Z"
        fill={fill || '#1E4164'}
      />
      <path
        d="M19.7002 9.71143C19.1002 9.71143 18.7002 10.1114 18.7002 10.7114C18.7002 11.3114 19.2002 11.7114 19.7002 11.7114C20.3002 11.7114 20.7002 11.3114 20.7002 10.7114C20.7002 10.2114 20.3002 9.71143 19.7002 9.71143Z"
        fill="white"
      />
      <path
        d="M15.3001 11.0117C13.0001 11.0117 11.1001 12.9117 11.1001 15.2117C11.1001 17.5117 13.0001 19.4117 15.3001 19.4117C17.6001 19.4117 19.5001 17.5117 19.5001 15.2117C19.5001 12.9117 17.6001 11.0117 15.3001 11.0117ZM15.3001 17.9117C13.8001 17.9117 12.6001 16.7117 12.6001 15.2117C12.6001 13.7117 13.8001 12.5117 15.3001 12.5117C16.8001 12.5117 18.0001 13.7117 18.0001 15.2117C18.0001 16.7117 16.8001 17.9117 15.3001 17.9117Z"
        fill="white"
      />
      <path
        d="M18.6002 23.8115H11.8002C9.0002 23.8115 6.7002 21.5115 6.7002 18.7115V11.9115C6.7002 9.11152 9.0002 6.81152 11.8002 6.81152H18.6002C21.4002 6.81152 23.7002 9.11152 23.7002 11.9115V18.6115C23.8002 21.5115 21.5002 23.8115 18.6002 23.8115ZM11.8002 8.31152C9.8002 8.31152 8.3002 9.91152 8.3002 11.8115V18.6115C8.3002 20.6115 9.9002 22.1115 11.8002 22.1115H18.6002C20.6002 22.1115 22.1002 20.5115 22.1002 18.6115V11.8115C22.1002 9.81152 20.5002 8.31152 18.6002 8.31152H11.8002Z"
        fill="white"
      />
    </svg>
  );
};
export default InstagramIcon;
