import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { Language, LANGUAGE } from '../new-lib/constants/i18n-constants';
import { COOKIE_KEYS, CookiesUtils } from '../new-lib/utilities/cookies-utils';
import { LanguageUtils } from '../new-lib/utilities/language-utils';
import { logEvent } from '../new-lib/analytics';

interface LanguageSwitch {
  language: LANGUAGE;
  setLanguage: (_language: LANGUAGE, save?: boolean) => void;
  changeLanguage: () => void;
}

export const useLanguageSwitch = (): LanguageSwitch => {
  const {
    i18n: { language, changeLanguage: _changeLanguage }
  } = useTranslation();
  const router = useRouter();

  const setLanguage = useCallback(
    async (_language: LANGUAGE, save = true) => {
      if (save) {
        CookiesUtils.addCookie({
          key: COOKIE_KEYS.NEXT_LOCALE,
          value: _language
        });
      }

      logEvent.click('locale_change', 'navbar', 'navbar');

      await router.replace(router.asPath, router.asPath, {
        locale: _language,
        shallow: false
      });

      router.reload();
    },
    [router]
  );

  const changeLanguage = useCallback((): void => {
    setLanguage(
      LanguageUtils.isArabic(language) ? LANGUAGE.ENGLISH : LANGUAGE.ARABIC
    );
  }, [language, setLanguage]);

  return {
    language: Language.fromString(language),
    setLanguage,
    changeLanguage
  };
};
