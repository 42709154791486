import styled from 'styled-components';
import { useRouter } from 'next/router';
import {
  getImagePath,
  getPageLocation
} from '../../../../../../helpers/common';
import ImageWrapper from '../../../../../../new-components/ImageWrapper';
import { logEvent } from '../../../../../../new-lib/analytics';
import Link from 'next/link';
import LaunchesBannerHeader from '../LaunchesBannerHeader';
import { LaunchesProps } from '../../../types';

interface ContainerProps {
  locale?: string;
}
interface LaunchesBannerContainerProps {
  locale?: string;
}

const MobileLaunchesBanner = ({ launches }: LaunchesProps) => {
  const { locale, asPath } = useRouter();

  const getLaunchesBanner = (launches: any[]) => {
    const handleLaunchBannerClick = (launch?: any) => {
      logEvent.click('lnch_click', getPageLocation(asPath), 'launch', {
        cmpnd_id: launch?.id,
        cmpnd_name: launch?.title,
        area_id: launch?.area?.id,
        area_name: launch?.area?.name
      });
    };

    const getSingleLaunchBanner = (launch: any) => {
      return (
        <SingleLaunchBannerContainer locale={locale}>
          <Link
            href={`/compound/${launch.slug}`}
            className="launch-image"
            onClick={() => handleLaunchBannerClick(launch)}
          >
            <ImageWrapper
              src={getImagePath(
                launch.imageUrl || launch?.marketing_image_square_path
              )}
              alt="banner-image"
              objectFit="cover"
              style={{
                borderRadius: '8px',
                border: '1px solid #E6E6E6',
                cursor: 'pointer'
              }}
              fill
              imageSize="low"
              sizes="100vw"
              priority
            />
          </Link>
        </SingleLaunchBannerContainer>
      );
    };

    const getLaunches = () => {
      return launches?.map((launch, index) => (
        <Link
          href={`/compound/${launch.slug}`}
          prefetch={index > 2}
          key={launch?.slug}
          className="launch-image"
          onClick={() => handleLaunchBannerClick(launch)}
        >
          <ImageWrapper
            src={getImagePath(
              launch?.imageUrl || launch?.marketing_image_square_path
            )}
            alt="banner-image"
            objectFit="cover"
            width={200}
            height={113}
            style={{
              borderRadius: '8px',
              border: '1px solid #E6E6E6',
              cursor: 'pointer'
            }}
            imageSize="low"
            sizes="70vw"
            priority={index < 2}
          />
        </Link>
      ));
    };
    const getMultipleLaunchesBanner = () => {
      return (
        <MultipleLaunchesBannerContainer>
          {getLaunches()}
        </MultipleLaunchesBannerContainer>
      );
    };

    if (launches.length === 1) {
      return getSingleLaunchBanner(launches[0]);
    } else {
      return getMultipleLaunchesBanner();
    }
  };
  return (
    <Container
      locale={locale}
      className="banner-container"
      data-test="launches-banner"
    >
      <LaunchesBannerHeader />
      {getLaunchesBanner(launches)}
    </Container>
  );
};

const Container = styled.div<ContainerProps>`
  width: 100%;
  background-color: ${props => props.theme.light.colors.white};
  display: flex;
  flex-direction: column;
  border-radius: ${props => props.theme.borderRadius};
  gap: 8px;
`;

const SingleLaunchBannerContainer = styled.div<LaunchesBannerContainerProps>`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;

  .launch-image {
    position: relative;
    width: 100%;
    height: 203px;

    img {
      border-radius: 8px;
    }
  }
`;

const MultipleLaunchesBannerContainer = styled.div<LaunchesBannerContainerProps>`
  display: flex;
  gap: 16px;
  overflow-x: auto;
  overflow-y: hidden;

  .launch-image {
    width: 200px;
    height: 113px;
  }

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export default MobileLaunchesBanner;
