import styled from 'styled-components';
import SearchLocationResults, {
  SearchInputResult
} from './SearchLocationResults';

interface DropdownProps {
  searchResults: SearchInputResult[];
  isError?: boolean;
  handleSelect: (selection: SearchInputResult) => void;
  dropdownRef?: any;
}

const SearchDropdown = ({
  searchResults,
  isError,
  handleSelect,
  dropdownRef
}: DropdownProps) => {
  return (
    <Dropdown
      className="container-fluid search-dropdown"
      ref={dropdownRef}
      data-test="search-input-dropdown"
    >
      {!isError && (
        <SearchLocationResults
          searchResults={searchResults}
          handleSelect={handleSelect}
        />
      )}
    </Dropdown>
  );
};

const Dropdown = styled.div`
  background-color: ${props => props.theme.light.backgroundColor};
  width: 100%;
  max-width: 600px;
  overflow-y: auto;
  border-radius: 10px;
  position: absolute;
  top: 60px;
  padding: 16px;
  max-height: 360px;
  box-shadow: 0px 4px 10px 0px #76767625;
  z-index: 99;

  .loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999999999999;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: ${props =>
      props.theme.breakPoints.mobile.max}) {
    height: calc(100vh - 130px);
    position: fixed;
    top: 130px;
    left: 0%;
    max-height: unset;
    border-radius: 0;
    box-shadow: none;
  }
`;

export default SearchDropdown;
