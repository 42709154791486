import styled from 'styled-components';
import PrevArrowIcon from '../../../../../new-components/svg-icons/PrevArrowIcon';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { getImagePath, getPageLocation } from '../../../../../helpers/common';
import ImageWrapper from '../../../../../new-components/ImageWrapper';
import Slider from '../../../../../new-components/Slider';
import Link from 'next/link';
import { logEvent } from '../../../../../new-lib/analytics';
import LaunchesBannerHeader from './LaunchesBannerHeader';
import { LaunchesProps } from '../../types';

interface LaunchesBannerContainerProps {
  locale?: string;
  path?: string;
}

const LaunchesBanner = ({ launches }: LaunchesProps) => {
  const { t } = useTranslation('launches');
  const router = useRouter();
  const { locale } = useRouter();

  const getLaunchesBanner = (launches: any[]) => {
    const handleExploreButtonClick = () => {
      logEvent.click('lnch_view_all', getPageLocation(router.asPath), 'launch');
    };

    const handleLaunchBannerClick = (launch: any) => {
      logEvent.click('lnch_click', getPageLocation(router.asPath), 'launch', {
        cmpnd_id: launch?.id,
        cmpnd_name: launch?.title,
        area_id: launch?.area?.id,
        area_name: launch?.area?.name
      });
    };

    const getSingleLaunchBanner = (launch: any) => (
      <SingleLaunchBannerContainer locale={locale} path={router.asPath}>
        <Link
          href={`/compound/${launch?.slug}`}
          className="banner-image"
          onClick={() => handleLaunchBannerClick(launch)}
        >
          <ImageWrapper
            src={getImagePath(
              launch?.imageUrl || launch?.marketing_image_square_path
            )}
            alt="banner-image"
            objectFit="cover"
            width={675}
            height={361}
            style={{
              width: '100%',
              height: 'auto',
              borderRadius: '8px',
              border: '1px solid #E6E6E6'
            }}
            imageSize="medium"
            priority
            sizes="50vw"
          />
        </Link>

        <div className="action-side">
          <div className="title">
            <h3>
              {t('explore_new')} <span>{t('launches')}</span>
            </h3>
            <p>{t('launches_subtitle')}</p>
          </div>

          <Link
            className="launch-btn"
            href="/new-launches"
            prefetch={false}
            onClick={handleExploreButtonClick}
          >
            {t('explore_more')}
            <PrevArrowIcon stroke="white" />
          </Link>
        </div>
      </SingleLaunchBannerContainer>
    );

    const getMultipleLaunchesBanner = () => {
      const getSliderChildren = () =>
        launches?.map((launch, index) => (
          <Link
            href={`/compound/${launch.slug}`}
            prefetch={index > 2}
            key={`${launch?.id}-${index}`}
            className="banner-image"
            onClick={() => handleLaunchBannerClick(launch)}
          >
            <ImageWrapper
              src={getImagePath(
                launch?.imageUrl || launch?.marketing_image_square_path
              )}
              alt="banner-image"
              objectFit="cover"
              width={393}
              height={221}
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: '8px',
                border: '1px solid #E6E6E6',
                cursor: 'pointer'
              }}
              priority={index < 3}
              onClick={() => handleLaunchBannerClick(launch)}
              imageSize="medium"
              sizes="80vw"
            />
          </Link>
        ));

      const launchesSliderProps = {
        width: '393px',
        height: '221px',
        slidesPerView: 'auto' as 'auto',
        spaceBetweenSlides: 16,
        slidesPerGroup: 1,
        children: getSliderChildren()
      };

      return (
        <MultipleLaunchesBannerContainer>
          <LaunchesBannerHeader />
          <Slider {...launchesSliderProps} />
        </MultipleLaunchesBannerContainer>
      );
    };

    if (launches.length === 1) {
      return getSingleLaunchBanner(launches[0]);
    } else {
      return getMultipleLaunchesBanner();
    }
  };
  return (
    <Container data-test="launches-banner">
      {getLaunchesBanner(launches)}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  background-color: ${props => props.theme.light.colors.white};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SingleLaunchBannerContainer = styled.div<LaunchesBannerContainerProps>`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 48px;

  .banner-image {
    width: 50%;
  }

  .action-side {
    width: 50%;
    color: ${props => props.theme.light.colors.primaryBlue};
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 16px;

    .title {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 4px;
      h3 {
        font-size: 32px;
        font-family: materialBold;
        text-transform: uppercase;
      }
      p {
        font-size: ${props => props.theme.font.sizes.s};
        color: ${props => props.theme.light.colors.black};
        font-family: materialLight;
      }
    }

    .launch-btn {
      width: 240px;
      height: 50px;
      border-radius: ${props => props.theme.borderRadius};
      background-color: ${props => props.theme.light.colors.primaryBlue};
      border: 1px solid ${props => props.theme.light.colors.white};
      color: ${props => props.theme.light.colors.white};
      font-size: ${props => props.theme.font.sizes.l};
      font-family: materialMedium;
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;
      justify-content: center;

      svg {
        width: 6px;
        transform: ${props => (props.locale == 'en' ? 'rotate(180deg)' : '')};
      }
    }
  }
`;

const MultipleLaunchesBannerContainer = styled.div<LaunchesBannerContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-x: auto;

  .banner-image {
    width: 393px;
    height: 221px;
  }
`;

export default LaunchesBanner;
