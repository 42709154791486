type PropsType = {
  styles?: object;
  fill?: string;
  stroke?: string;
  hoverFill?: string;
};

const FacebookIcon = ({ styles, fill, stroke, hoverFill }: PropsType) => {
  return (
    <svg
      className="facebookSvg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      style={styles}
      xmlns="http://www.w3.org/2000/svg"
    >
      <style>
        {`.facebookSvg:hover .fillPath {fill: ${hoverFill || '#1E4164'};}`}
      </style>
      <path
        className="fillPath"
        d="M29.2 15.2113C29.2 7.11133 22.7 0.611328 14.6 0.611328C6.5 0.611328 0 7.11133 0 15.2113C0 22.5113 5.3 28.6113 12.3 29.7113V19.4113H8.6V15.2113H12.3V12.0113C12.3 8.31133 14.5 6.31133 17.8 6.31133C19.4 6.31133 21.1 6.61133 21.1 6.61133V10.2113H19.3C17.5 10.2113 16.9 11.3113 16.9 12.5113V15.2113H21L20.4 19.4113H17V29.6113C23.9 28.6113 29.2 22.5113 29.2 15.2113Z"
        fill={fill || '#1E4164'}
      />
      <path
        d="M20.3001 19.4115L20.9001 15.2115H16.8001V12.5115C16.8001 11.3115 17.4001 10.2115 19.2001 10.2115H21.0001V6.61152C21.0001 6.61152 19.3001 6.31152 17.7001 6.31152C14.4001 6.31152 12.2001 8.31152 12.2001 12.0115V15.2115H8.6001V19.4115H12.3001V29.6115C13.0001 29.7115 13.8001 29.8115 14.6001 29.8115C15.4001 29.8115 16.1001 29.7115 16.9001 29.6115V19.4115H20.3001Z"
        fill="white"
      />
    </svg>
  );
};
export default FacebookIcon;
