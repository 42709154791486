import Link from 'next/link';
import router, { useRouter } from 'next/router';
import styled, { DefaultTheme, ThemeContext } from 'styled-components';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { useContext } from 'react';

// Components
import GradientLine from '../../new-components/ui/GradientLine';

// Assets
import nawyIcon from '../../public/assets/icons/common/nawy.svg';
import FacebookIcon from '../../new-components/svg-icons/FacebookIcon';
import LinkedinIcon from '../../new-components/svg-icons/LinkedinIcon';
import TwitterIcon from '../../new-components/svg-icons/TwitterIcon';
import YoutubeIcon from '../../new-components/svg-icons/YoutubeIcon';
import InstagramIcon from '../../new-components/svg-icons/InstagramIcon';
import DownloadApp from '../ui/DownloadApp';
import { FooterLinkEntity, FooterLinksDTO, PropertiesFooterLinkEntity } from '../../new-lib/dtos';

interface FooterProps {
  footerLinks: FooterLinksDTO;
  getLinkRef: (
    item: FooterLinkEntity | PropertiesFooterLinkEntity,
    linkTo: string
  ) => string;
  getFooterLinks: () => JSX.Element[];
  handleItemClick: (itemName: string) => void;
}

const Footer: React.FC<FooterProps> = props => {
  const { locale } = useRouter();
  const { t } = useTranslation('common');
  const theme = useContext(ThemeContext) as DefaultTheme;
  return (
    <FooterContainer id="footer">
      <StyledFooter className="container-fluid">
        <FooterLinksContainer>{props.getFooterLinks()}</FooterLinksContainer>
        <Link itemProp="url" href="/" locale={locale} prefetch={false}>
          <Image
            src={nawyIcon}
            alt="Nawy Icon"
            style={{ width: 'auto', height: 'auto' }}
            onClick={() => props.handleItemClick('nawy_logo_click')}
            itemProp="image"
          />
        </Link>
        <SocialMediaContainer
          locale={locale}
          className="social-media-footer"
          itemProp="publisher"
        >
          <div className="socailMediaTitle">
            <span>{t('real_estate_company')}</span>
            <h6>{t('download_our_application')}</h6>
          </div>
          <div className="socialIconsWrapper">
            <div className="socialIcons">
              <Link
                itemProp="url"
                prefetch={false}
                href="https://www.facebook.com/nawyrealestate"
                target="_blank"
                rel="noreferrer"
                onClick={() => props.handleItemClick('facebook_click')}
                aria-label="facebook-link"
              >
                <FacebookIcon
                  styles={{ objectFit: 'contain' }}
                  fill=""
                  hoverFill="#165688"
                />
              </Link>
              <Link
                itemProp="url"
                prefetch={false}
                href="https://www.linkedin.com/company/nawyestate/mycompany/"
                target="_blank"
                rel="noreferrer"
                onClick={() => props.handleItemClick('linkedin_click')}
                aria-label="linkedin-link"
              >
                <LinkedinIcon
                  styles={{ objectFit: 'contain' }}
                  fill=""
                  hoverFill="#165688"
                />
              </Link>
              <Link
                itemProp="url"
                prefetch={false}
                href="https://www.youtube.com/channel/UCAtydzjr9JcWM4UNXU1AXig"
                target="_blank"
                rel="noreferrer"
                onClick={() => props.handleItemClick('youtube_click')}
                aria-label="youtube-link"
              >
                <YoutubeIcon
                  styles={{ objectFit: 'contain' }}
                  fill=""
                  hoverFill="#165688"
                />
              </Link>
              <Link
                itemProp="url"
                prefetch={false}
                href="https://www.instagram.com/nawyrealestate/"
                target="_blank"
                rel="noreferrer"
                onClick={() => props.handleItemClick('instagram_click')}
                aria-label="instagram-link"
              >
                <InstagramIcon
                  styles={{ objectFit: 'contain' }}
                  fill=""
                  hoverFill="#165688"
                />
              </Link>
              <Link
                itemProp="url"
                prefetch={false}
                href="https://x.com/nawyegypt"
                target="_blank"
                rel="noreferrer"
                onClick={() => props.handleItemClick('twitter_click')}
                aria-label="twitter-link"
              >
                <TwitterIcon
                  styles={{ objectFit: 'contain' }}
                  fill=""
                  hoverFill="#165688"
                />
              </Link>
            </div>
            <DownloadApp
              iconWidth="auto"
              iconHeight="38px"
              iconColor={theme.light.colors.darkGrey}
              eventLocation="Footer"
            />
          </div>
          <GradientLine height={'2px'} />
          <div className="footerEndSection">
            <span dir="ltr">© Copyright 2025 - Nawy.</span>
            <div className="footerEndContent">
              <Link
                itemProp="url"
                href="/"
                prefetch={false}
                onClick={() => props.handleItemClick('home_click')}
              >
                {t('home')}
              </Link>
              <Link
                itemProp="url"
                href="/search"
                prefetch={false}
                onClick={() => props.handleItemClick('search_click')}
              >
                {t('Search')}
              </Link>
              <Link
                itemProp="url"
                prefetch={false}
                onClick={() => {
                  props.handleItemClick('blog_click');
                  router.push(
                    `https://www.nawy.com/blog/${locale == 'ar' ? 'ar' : ''}`,
                    '',
                    {
                      locale: locale
                    }
                  );
                }}
                href={`https://www.nawy.com/blog/${locale == 'ar' ? 'ar' : ''}`}
              >
                {t('Blog')}
              </Link>
              <Link
                itemProp="url"
                href="/about-us"
                prefetch={false}
                onClick={() => props.handleItemClick('about_us_click')}
              >
                {t('About')}
              </Link>
              <Link
                itemProp="url"
                href="/contact"
                prefetch={false}
                onClick={() => props.handleItemClick('contact_us_click')}
              >
                {t('Contact')}
              </Link>
              <Link
                itemProp="url"
                href="/terms"
                prefetch={false}
                onClick={() => props.handleItemClick('terms_conditions_click')}
              >
                {t('terms_of_use')}
              </Link>
              <Link
                itemProp="url"
                href="/privacy-policy"
                prefetch={false}
                onClick={() => props.handleItemClick('privacy_policy_click')}
              >
                {t('privacy_policy')}
              </Link>
              <Link
                itemProp="url"
                href="/how-it-works"
                prefetch={false}
                onClick={() => props.handleItemClick('how_it_works_click')}
              >
                {t('how_it_works')}
              </Link>
              <Link
                itemProp="url"
                href="/new-launches"
                prefetch={false}
                onClick={() => props.handleItemClick('launches_click')}
              >
                {t('launches')}
              </Link>
              <Link
                itemProp="url"
                href="/nawy-now"
                prefetch={false}
                className="nawyNow"
                onClick={() => props.handleItemClick('nawynow_click')}
              >
                Nawy Now
              </Link>
            </div>
          </div>
        </SocialMediaContainer>
      </StyledFooter>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  width: 100%;
  background-color: ${props => props.theme.light.colors.white};
  border-top: 1px solid #e6e6e6;
  margin-top: 60px;
`;

const StyledFooter = styled.div`
  margin: auto;
  height: auto;
  padding: 30px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;
const FooterLinksContainer = styled.div`
  padding: 0px 0px 16px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  gap: 50px;
`;
const SocialMediaContainer = styled.div<any>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  .socailMediaTitle {
    display: flex;
    justify-content: space-between;
    color: ${props => props.theme.light.colors.darkGrey};
    font-family: materialRegular;
    font-size: ${props => props.theme.font.sizes.s};
  }
  .socialIconsWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .socialIcons {
      align-items: center;
      display: flex;
      gap: 6px;
      span {
        width: 29.2px;
        height: 29.2px;
        border-radius: 50%;
        background: ${props => props.theme.light.colors.primaryBlue};
      }
    }
    .socialApps {
      display: flex;
      gap: 3px;
    }
  }
  .footerEndSection {
    display: flex;
    justify-content: space-between;
    color: ${props => props.theme.light.colors.black};
    font-family: materialMedium;
    font-size: ${props => props.theme.font.sizes.s};
    margin-top: 8px;
    .footerEndContent {
      display: flex;
      gap: 8px;
      font-family: materialMedium;
      font-size: ${props => props.theme.font.sizes.s};
      color: ${props => props.theme.light.colors.darkGrey};
      cursor: pointer;
      padding-right: 28px;
      a {
        &:hover {
          color: ${props => props.theme.light.colors.primaryOrange};
        }
      }
      .nawyNow {
        position: relative;
        ::after {
          content: ${props => (props.locale === 'en' ? "'New'" : "'جديد'")};
          position: absolute;
          top: -12px;
          right: ${props => (props.locale === 'ar' ? 'unset' : '-28px')};
          left: ${props => (props.locale === 'ar' ? '-28px' : 'unset')};
          background-color: ${props => props.theme.light.colors.primaryOrange};
          font-size: 8px;
          padding: 2px 5px;
          color: ${props => props.theme.light.colors.white};
          border-radius: ${props => props.theme.borderRadius};
          font-family: materialRegular;
        }
      }
    }
  }
`;

export default Footer;
