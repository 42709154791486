import { useContext, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import styled, {
  ThemeContext,
  css,
  keyframes,
  DefaultTheme
} from 'styled-components';
import { SearchFiltersTypes } from '../../../../reducers/search-filters/reducer';
import { SearchFiltersActionTypes } from '../../../../reducers/search-filters/actions';
import FilterContainer from './FilterContainer';
import MultiSelectContainer from '../search-filters/multi-select-view/MultiSelectContainer';
import SingleSelectContainer from '../search-filters/single-select-view/SingleSelectContainer';
import SingleSelectPresentaion from '../search-filters/single-select-view/SingleSelectPresentation';
import ButtonTextIcon from '../../../../new-components/ui/ButtonTextIcon';
import ResetIcon from '../../../../new-components/svg-icons/ResetIcon';
import MinMaxInputContainer from '../search-filters/min-max-view/MinMaxInputContainer';
import SingleInputContainer from '../search-filters/single-input-view/SingleInputContainer';
import ToggleFilterContainer from '../search-filters/toggle-view/ToggleFilterContainer';
import { resetFilters } from '../../../../helpers/search-filters';
import { logEvent } from '../../../../new-lib/analytics';
import Button from '../../../../new-components/ui/Button';
import useOnClickOutside from '../../../../hooks/ClickOutside';
import FilterIcon from '../../../../new-components/svg-icons/FilterIcon';
import CloseIcon from '../../../../new-components/svg-icons/CloseIcon';
import ButtonIcon from '../../../../new-components/ui/ButtonIcon';
import CheckBoxContainer from '../search-filters/checkbox-view/CheckboxContainer';
import SliderContainer from '../search-filters/slider-view/SliderContainer';
import LocationSearchFilter from '../search-filters/location-search/LocationSearch';
import FinancingFilter from '../search-filters/financing-filter/FinancingFilter';
import { useSelector } from 'react-redux';
import { isEqual } from '../../../../helpers/isEqual';
import {
  SearchContext,
  SearchContextInterface
} from '../../../search-new/context/SearchContext';

const FilterMenu = () => {
  const router = useRouter();
  const { t } = useTranslation('search');

  const [isMoveNowFiltered, setIsMoveNowFiltered] = useState<boolean>(false);
  const {
    showFilters,
    setShowFilters,
    searchFiltersDispatch,
    filterOptions,
    searchFiltersState,
    maxDeliveryYear
  } = useContext(SearchContext) as SearchContextInterface;
  const searchBodyResults = useSelector(
    (state: any) => state.searchResults.searchResults,
    isEqual
  );
  const theme = useContext(ThemeContext) as DefaultTheme;
  const priceRef = useRef(null);
  const paymentRef = useRef(null);
  const areaRef = useRef(null);
  const unit_area_list = [
    { value: 50, options: { displayedValue: '50' } },
    { value: 80, options: { displayedValue: '80' } },
    { value: 100, options: { displayedValue: '100' } },
    { value: 150, options: { displayedValue: '150' } },
    { value: 200, options: { displayedValue: '200' } },
    { value: 250, options: { displayedValue: '250' } },
    { value: 300, options: { displayedValue: '300' } },
    { value: 350, options: { displayedValue: '350' } },
    { value: 400, options: { displayedValue: '400' } }
  ];
  const isNawyNowPage = router.asPath.includes('/nawy-now');

  const amenities = [
    {
      value: 'has_garden',
      options: { displayedValue: t('garden') },
      filterType: SearchFiltersTypes.HAS_GARDEN
    },
    {
      value: 'has_roof',
      options: { displayedValue: t('roof') },
      filterType: SearchFiltersTypes.HAS_ROOF
    }
  ];

  const isSearchResult = searchBodyResults.loadedSearchResultsCount > 0;
  const getFilterBtnText = () => {
    return searchBodyResults.isLoading
      ? '. . .'
      : isSearchResult
      ? `${t('show')} ${searchBodyResults.loadedSearchResultsCount} ${t(
          'results'
        )}`
      : t('no_results_found');
  };

  const closeMenu = () => {
    setShowFilters(false);
    logEvent.search(
      'filter_show_results',
      'DesktopFiltersMenu',
      'filter',
      searchFiltersState
    );
  };

  const menuRef = useRef(null);
  useEffect(() => {
    const handlePopState = () => {
      setShowFilters(false);
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [showFilters, router.asPath]);

  useOnClickOutside(menuRef, menuRef, closeMenu);

  useEffect(() => {
    if (!document || router.asPath.includes('/sahel-map')) return;
    let body = document.body;
    let tag: HTMLDivElement | null = null;

    if (showFilters) {
      tag = document.createElement('div');
      tag.classList.add('overlay-screen');
      body.appendChild(tag);
      body.style.pointerEvents = 'none';
    } else {
      if (tag) body.removeChild(tag);
      body.style.pointerEvents = 'all';
    }

    return () => {
      if (tag && body.contains(tag)) {
        body.removeChild(tag);
      }
    };
  }, [showFilters]);

  useEffect(() => {
    const getMaxDeliveryYear = () => {
      if (searchFiltersState.searchBody.nawy_now) {
        setIsMoveNowFiltered(true);
      } else {
        setIsMoveNowFiltered(false);
      }
    };
    getMaxDeliveryYear();
  }, [searchFiltersState.searchBody.nawy_now]);

  return showFilters && !router.asPath.includes('/sahel-map') ? (
    <SideBar ref={menuRef} dir={router.locale}>
      <div className="menu-header">
        <div className="menu-title">
          <FilterIcon />
          <span className="headline-2">{t('filter_by')}</span>
        </div>

        <ButtonIcon
          icon={<CloseIcon />}
          btnClass="close-btn"
          backgroundColor="#F3F3F3"
          border="none"
          color="none"
          onClick={closeMenu}
        />
      </div>
      <div className="filters-container">
        {!router.asPath.includes('/compound') && (
          <FilterContainer name={t('find')}>
            <ToggleFilterContainer
              options={filterOptions.category}
              filterType={SearchFiltersTypes.CATEGORY}
            />
          </FilterContainer>
        )}

        {router.asPath.includes('/nawy-now') && (
          <FilterContainer name={t('location')}>
            <LocationSearchFilter />
          </FilterContainer>
        )}

        <FilterContainer name={t('property_types')}>
          <SliderContainer
            searchFiltersState={searchFiltersState}
            searchFiltersDispatch={searchFiltersDispatch}
            filterName={SearchFiltersTypes.PROPERTY_TYPES}
            filterTypes={filterOptions[SearchFiltersTypes.PROPERTY_TYPES]}
            options={searchBodyResults.loadedPropertyTypes}
            withCountDisplay={true}
            sliderLocation="MobileFiltersMenu"
          />
        </FilterContainer>

        <FilterContainer name={t('property_features')}>
          <CheckBoxContainer options={amenities} />
        </FilterContainer>

        <FilterContainer name={t('bedrooms')}>
          <MultiSelectContainer
            maxDisplayedValue={5}
            shapeClass="circleShape"
            filterType={SearchFiltersTypes.BEDROOMS}
          />
        </FilterContainer>

        <FilterContainer name={t('bathrooms')}>
          <MultiSelectContainer
            maxDisplayedValue={5}
            shapeClass="circleShape"
            filterType={SearchFiltersTypes.BATHROOMS}
          />
        </FilterContainer>

        <FilterContainer
          name={t('payments')}
          containerRef={paymentRef}
          displayedValues={[
            {
              name: SearchFiltersTypes.DOWN_PAYMENT,
              value: searchFiltersState.searchFilters.down_payment,
              preValue: '',
              postValue: t('DP'),
              filterAction:
                SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE
            },
            {
              name: SearchFiltersTypes.MONTHLY_INSTALLMENTS,
              value: searchFiltersState.searchFilters.monthly_installments,
              preValue: '',
              postValue: t('monthly'),
              filterAction:
                SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE
            },
            {
              name: SearchFiltersTypes.INSTALLMENT_YEARS,
              value:
                searchFiltersState.searchFilters.installment_years.length > 0
                  ? searchFiltersState.searchFilters.installment_years
                  : undefined,
              preValue: '',
              postValue: t('years'),
              filterAction:
                SearchFiltersActionTypes.UPDATE_FILTERS_WITH_MULTISELECT
            }
          ]}
        >
          <div className="installment-wrapper">
            <SingleInputContainer
              headline={t('down_payment')}
              curruncy={t('egp')}
              placeholder={`${t('max_amount')}`}
              min={parseInt(filterOptions.down_payment[0].value.toString())}
              filterType={SearchFiltersTypes.DOWN_PAYMENT}
            />
            <SingleInputContainer
              headline={t('monthly_installments')}
              curruncy={t('egp')}
              min={parseInt(
                filterOptions.monthly_installments[0].value.toString()
              )}
              placeholder={`${t('max_amount')}`}
              filterType={SearchFiltersTypes.MONTHLY_INSTALLMENTS}
            />
          </div>
          <h3
            className="text-3"
            style={{ marginBottom: '8px', fontSize: '14px' }}
          >
            {`${t('years_of_installments')}`}
          </h3>
          <MultiSelectContainer
            maxDisplayedValue={6}
            shapeClass="circleShape"
            filterType={SearchFiltersTypes.INSTALLMENT_YEARS}
          />
        </FilterContainer>

        <FilterContainer
          name={t('price_range')}
          subText={`(${t('egp')})`}
          containerRef={priceRef}
          displayedValues={[
            {
              name: SearchFiltersTypes.MIN_PRICE,
              value: searchFiltersState.searchFilters.min_price,
              preValue: t('min'),
              postValue: '',
              filterAction:
                SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE
            },
            {
              name: SearchFiltersTypes.MAX_PRICE,
              value: searchFiltersState.searchFilters.max_price,
              preValue: t('max'),
              postValue: '',
              filterAction:
                SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE
            }
          ]}
        >
          <MinMaxInputContainer
            maxFilterType={SearchFiltersTypes.MAX_PRICE}
            minFilterType={SearchFiltersTypes.MIN_PRICE}
            minList={filterOptions.min_price}
            maxList={filterOptions.max_price}
            min={parseInt(filterOptions.min_price[0].value.toString())}
          />
        </FilterContainer>

        {!isNawyNowPage && (
          <FilterContainer name={t('delivery_date')}>
            <SingleSelectContainer
              filterType={SearchFiltersTypes.DELIVERY_DATE}
              key={'Delivery Date'}
              shapeClass="straightShape"
              Component={SingleSelectPresentaion}
              maxDisplayedValue={maxDeliveryYear}
              isMoveNowFiltered={isMoveNowFiltered}
            />
          </FilterContainer>
        )}

        <FilterContainer
          name={t('square_meter')}
          subText={`(${t('m2')})`}
          containerRef={areaRef}
          displayedValues={[
            {
              name: SearchFiltersTypes.MIN_UNIT_AREA,
              value: searchFiltersState.searchFilters.min_unit_area,
              preValue: t('min'),
              postValue: '',
              filterAction:
                SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE
            },
            {
              name: SearchFiltersTypes.MAX_UNIT_AREA,
              value: searchFiltersState.searchFilters.max_unit_area,
              preValue: t('max'),
              postValue: '',
              filterAction:
                SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE
            }
          ]}
        >
          <MinMaxInputContainer
            maxFilterType={SearchFiltersTypes.MAX_UNIT_AREA}
            minFilterType={SearchFiltersTypes.MIN_UNIT_AREA}
            minList={unit_area_list}
            maxList={unit_area_list}
            min={parseInt(filterOptions.min_unit_area[0].value.toString())}
          />
        </FilterContainer>

        <FilterContainer name={t('finishing')}>
          <MultiSelectContainer
            key={'Completion Type'}
            shapeClass="straightShape"
            filterType={SearchFiltersTypes.COMPLETION_TYPE}
          />
        </FilterContainer>

        {!isNawyNowPage && (
          <>
            {!router.asPath.includes('/compound') && (
              <FilterContainer name={t('sale_type')}>
                <MultiSelectContainer
                  key={'Sale Type'}
                  shapeClass="straightShape"
                  filterType={SearchFiltersTypes.SALE_TYPE}
                />
              </FilterContainer>
            )}
            <FinancingFilter />
          </>
        )}
      </div>

      <ActionsContainer>
        <Button
          text={getFilterBtnText()}
          backgroundColor={
            isSearchResult
              ? theme.light.colors.primaryBlue
              : theme.light.colors.grey
          }
          backgroundHover={
            !isSearchResult ? 'none' : theme.light.hoverColors.primaryBlue
          }
          fontSize={theme.font.sizes.s}
          padding="8px"
          color={theme.light.colors.white}
          hoverColor={theme.light.colors.white}
          border="none"
          onClick={closeMenu}
          disabled={!isSearchResult}
        />

        <ButtonTextIcon
          text={t('reset_all')}
          backgroundColor={theme.light.colors.white}
          backgroundHover="#F4F7FA"
          fontSize={theme.font.sizes.s}
          padding="8px"
          color="#344054"
          hoverColor={theme.light.hoverColors.primaryBlue}
          border="1px solid #D0D5DD"
          icon={<ResetIcon />}
          onClick={() => {
            resetFilters(
              router,
              searchFiltersDispatch,
              router.asPath.includes('/buy') ? false : true
            );
            logEvent.search(
              'filter_reset',
              'DesktopFiltersMenu',
              'filter',
              searchFiltersState
            );
          }}
        />
      </ActionsContainer>
    </SideBar>
  ) : null;
};

// Animation for LTR layout
const slideInFromRight = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

// Animation for RTL layout
const slideInFromLeft = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const SideBar = styled.div`
  position: fixed;
  top: 0px;
  inset-inline-end: 0px;
  width: 530px;
  height: 100vh;
  padding: 16px;
  background-color: ${props => props.theme.light.colors.white} !important;
  border: 1px solid #e6e6e6;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999999;
  pointer-events: all;

  opacity: 0;
  ${({ dir }) =>
    dir === 'ar'
      ? css`
          right: 0;
          animation: ${slideInFromLeft} 0.2s ease forwards;
        `
      : css`
          right: 0;
          animation: ${slideInFromRight} 0.2s ease forwards;
        `}
  will-change: transform, opacity;
  will-change: transform, opacity;

  .menu-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0px;

    .menu-title {
      display: flex;
      align-items: center;
      gap: 16px;

      svg {
        width: 25px;
        height: auto;
        path {
          fill: #d9d9d9;
        }
      }

      span {
        font-family: materialBold;
        font-size: ${props => props.theme.font.sizes.xl};
        color: ${props => props.theme.light.colors.black};
      }
    }

    .close-btn {
      width: 35px;
      height: 35px;
      border-radius: 3px;

      svg {
        width: 16px;
        height: 19px;
      }
    }
  }

  .filters-container {
    width: 100%;
    height: calc(100vh - 100px);
    overflow-y: auto;
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */
    padding-bottom: 16px;

    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const ActionsContainer = styled.div`
  position: relative;
  bottom: 0px;
  width: 100%;
  height: 100px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  > div {
    width: 48%;
    button {
      width: 100%;
      cursor: pointer;
      height: 40px;
      border-radius: 8px;

      span {
        font-family: materialBold;
      }
    }
  }
`;
export default FilterMenu;
