import sbjs from 'sourcebuster';

interface AdjustLinkParameters {
  encodedUrlPath: string;
  utmCampaign: string;
  utmAdgroup: string;
  utmSource: string;
  adjLabel: string | undefined;
}

interface SbjsInterface {
  get?: {
    current?: {
      src?: string;
      cmp?: string;
      mdm?: string;
    };
  };
}

export class AdjustLinkService {
  public static readonly host: string = 'https://nawy.com';
  public static readonly sbjs: SbjsInterface = sbjs;

  static getQueryParamValue(param: string): string {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param) || '';
  }

  static encodeSpecialCharacters(str: string): string {
    return encodeURIComponent(str).replace(/\(/g, '%28').replace(/\)/g, '%29');
  }

  static constructUtmAndParams(path?: string): AdjustLinkParameters {
    const currentUrlPath = path ?? '';
    const encodedUrlPath = this.encodeSpecialCharacters(
      `${this.host}${currentUrlPath.split('?')[0]}`
    );

    const utmSource = this.encodeSpecialCharacters(
      this.getQueryParamValue('utm_source') ||
        this.sbjs.get?.current?.src ||
        'button'
    );
    const utmCampaign = this.encodeSpecialCharacters(
      this.sbjs.get?.current?.cmp === '(none)'
        ? 'get_app'
        : this.sbjs.get?.current?.cmp!
    );
    const utmAdgroup = this.encodeSpecialCharacters(
      this.getQueryParamValue('utm_adgroup') || 'header'
    );

    // Construct adj_label only if relevant params are available
    const gclid = this.getQueryParamValue('gclid');
    const utmAccId = this.getQueryParamValue('utm_acc_id');
    const utmMedium =
      this.getQueryParamValue('utm_medium') || this.sbjs.get?.current?.mdm;
    const utmTerm = this.getQueryParamValue('utm_term');
    const gadSource = this.getQueryParamValue('gad_source');

    let adjLabelParts = [];
    if (gclid) adjLabelParts.push(`gclid=${gclid}`);
    if (utmAccId) adjLabelParts.push(`utm_acc_id=${utmAccId}`);
    if (utmMedium) adjLabelParts.push(`utm_medium=${utmMedium}`);
    if (utmTerm) adjLabelParts.push(`utm_term=${utmTerm}`);
    if (gadSource) adjLabelParts.push(`gad_source=${gadSource}`);

    const adjLabel: string | undefined =
      adjLabelParts.length > 0
        ? this.encodeSpecialCharacters(adjLabelParts.join('+'))
        : undefined;

    return { encodedUrlPath, utmCampaign, utmAdgroup, utmSource, adjLabel };
  }

  static handleOpenApp(path?: string): void {
    const adjDomain = process.env.NEXT_PUBLIC_DYNAMIC_LINK;
    const adjToken = process.env.NEXT_PUBLIC_ADJUST_TOKEN;

    const { encodedUrlPath, utmCampaign, utmAdgroup, utmSource, adjLabel } =
      this.constructUtmAndParams(path);

    window.location.href = `${adjDomain}${
      path ?? ''
    }?adj_t=${adjToken}&adj_fallback=${encodedUrlPath}&adj_redirect_macos=${encodedUrlPath}&adj_deeplink=${encodedUrlPath}&adj_campaign=${utmCampaign}&adj_adgroup=${utmAdgroup}&adj_creative=${utmSource}${
      adjLabel ? `&adj_label=${adjLabel}` : ''
    }`;
  }
}
