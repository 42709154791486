type PropsType = {
  styles?: object;
  fill?: string;
  stroke?: string;
  hoverFill?: string;
};

const LinkedinIcon = ({ styles, fill, stroke, hoverFill }: PropsType) => {
  return (
    <svg
      className="linkedinSvg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      style={styles}
      xmlns="http://www.w3.org/2000/svg"
    >
      <style>
        {`.linkedinSvg:hover .fillPath {fill: ${hoverFill || '#1E4164'};}`}
      </style>
      <path
        className="fillPath"
        d="M14.8002 29.8113C22.8636 29.8113 29.4002 23.2747 29.4002 15.2113C29.4002 7.14797 22.8636 0.611328 14.8002 0.611328C6.73684 0.611328 0.200195 7.14797 0.200195 15.2113C0.200195 23.2747 6.73684 29.8113 14.8002 29.8113Z"
        fill={fill || '#1E4164'}
      />
      <path
        d="M8.7 12.5112H11.5V21.6112H8.7V12.5112ZM10.1 8.01123C11 8.01123 11.7 8.71123 11.7 9.61123C11.7 10.5112 11 11.2112 10.1 11.2112C9.2 11.2112 8.5 10.5112 8.5 9.61123C8.5 8.71123 9.2 8.01123 10.1 8.01123Z"
        fill="white"
      />
      <path
        d="M13.3003 12.5114H16.0003V13.7114C16.4003 13.0114 17.3003 12.2114 18.7003 12.2114C21.6003 12.2114 22.1003 14.1114 22.1003 16.5114V21.5114H19.3003V17.1114C19.3003 16.0114 19.3003 14.7114 17.8003 14.7114C16.3003 14.7114 16.1003 15.8114 16.1003 17.0114V21.5114H13.3003V12.5114Z"
        fill="white"
      />
    </svg>
  );
};
export default LinkedinIcon;
