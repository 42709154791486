import { useSelector } from 'react-redux';
import styled from 'styled-components';
import CardBadge from '../CardBadge';
import { EntityTypes } from '../../../new-lib/constants/common';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import ImageWrapper from '../../ImageWrapper';
import { getImagePath, getReadyBy } from '../../../helpers/common';
import { handleDelivery } from '../../../helpers/cards';

interface ImageInfoSectionProps {
  entityData: {
    tags: string[];
    has_offer: boolean;
    has_launches: boolean;
    property_type: { name: string };
    logo_path: string;
    isNawyNow?: boolean;
    min_ready_by: number | string;
  };
  entityType: string;
}

const ImageInfoSection = ({
  entityData,
  entityType
}: ImageInfoSectionProps) => {
  const { locale } = useRouter();
  const { t } = useTranslation('common');
  const isMobile = useSelector((state: any) => state.app.isMobile);

  const getCompoundOfferBadgeLabel = () => {
    if (!entityData.has_offer && !entityData.has_launches) {
      return '';
    }

    if (entityData.has_launches) {
      return locale === 'en' ? 'Launch' : 'اطلاق جديد';
    }

    if (entityData.has_offer) {
      return locale === 'en' ? 'Offer' : 'عرض';
    }
  };

  const DeliveryDate = () => {
    const res = handleDelivery(entityData.min_ready_by.toString());
    return (
      <div className="deliverychild">
        <div>{t(res.firstLine)}</div>
        <div>{t(res.secondline.toString())}</div>
      </div>
    );
  };
  return (
    <Container locale={locale}>
      {isMobile ? (
        <CardBadge
          entityBadgeLabel={
            entityType == EntityTypes.PROPERTY
              ? entityData?.tags && entityData?.tags[0]
              : getCompoundOfferBadgeLabel()
          }
          entityTypeLabel={
            entityType == EntityTypes.PROPERTY
              ? entityData?.property_type?.name
              : t('compound')
          }
        />
      ) : (
        <div className="developer-img" data-cy="card-developer-logo">
          <ImageWrapper
            fill
            objectFit="cover"
            src={getImagePath(entityData.logo_path)}
            alt="developer logo"
            imageSize="medium"
            sizes="30vw"
          />
        </div>
      )}

      {entityType == EntityTypes.PROPERTY &&
        (entityData.isNawyNow && !isMobile ? (
          <div className="nawynow-ribbon">
            <ImageWrapper
              width={158.15}
              height={89}
              alt="Nawy-Now"
              src={`/assets/icons/common/nawy-now-ribbon-${locale}.svg`}
              style={{ right: '0px' }}
              sizes="50vw"
              className="nawynow-ribbon"
            />
          </div>
        ) : (
          <div className="delivery-date">
            {getReadyBy(entityData.min_ready_by) && <DeliveryDate />}
          </div>
        ))}
    </Container>
  );
};

const Container = styled.div<any>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .delivery-date {
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    .deliverychild {
      display: grid;
    }
    div:nth-child(1) {
      font-family: materialRegular;
      font-size: ${props => props.theme.font.sizes.xxs};
    }
    div:nth-child(2) {
      font-family: materialBold;
      font-size: ${props => props.theme.font.sizes.xl};
      justify-self: end;
    }
  }
  .nawynow-ribbon {
    position: absolute;
    right: ${props => (props.locale == 'en' ? '-1px' : 'unset')};
    left: ${props => (props.locale == 'ar' ? '-1px' : 'unset')};
    bottom: -13.6px;
  }
`;

export default ImageInfoSection;
