import Script from 'next/script';
import '../styles/main.css';
import { appWithTranslation } from 'next-i18next';
import { Provider } from 'react-redux';
import { wrapper } from '../redux/store';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import NextNProgress from 'nextjs-progressbar';
import Layout from '../new-components/layout';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import { theme } from '../styles/theme';
import GlobalStyles from '../styles/global-styles';
import SearchProvider from '../features/search-new/context/SearchContext';
import { AnalyticsScripts } from '../new-lib/analytics/analytics-scripts';
import isPropValid from '@emotion/is-prop-valid';

function MyApp({ Component, ...rest }: any) {
  const {
    store,
    props: { pageProps }
  } = wrapper.useWrappedStore(rest);
  const queryClient = new QueryClient();

  return (
    <ThemeProvider theme={theme}>
      <AnalyticsScripts />

      {pageProps?.schema && (
        <Script
          strategy={'beforeInteractive'}
          id="schema"
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(pageProps.schema) }}
        />
      )}

      {pageProps?.schemaFAQs && (
        <Script
          id="compound-faq-schema"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(pageProps.schemaFAQs)
          }}
        />
      )}

      {pageProps?.schemaArticle && (
        <Script
          id="compound-article-schema"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(pageProps.schemaArticle)
          }}
        />
      )}

      <Script
        strategy={'beforeInteractive'}
        id="organization-schema"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': ['Organization', 'Corporation'],
            url: 'www.nawy.com',
            brand: { '@type': 'Brand', name: 'Nawy' },
            name: 'Nawy',
            contactPoint: {
              '@type': 'ContactPoint',
              areaServed: 'eg',
              availableLanguage: ['ar', 'en']
            },
            sameAs: [
              'https://www.facebook.com/nawyrealestate',
              'https://www.linkedin.com/company/nawyestate/mycompany/',
              'https://www.youtube.com/channel/UCAtydzjr9JcWM4UNXU1AXig',
              'https://www.instagram.com/nawyrealestate/',
              'https://x.com/nawyegypt'
            ],
            potentialAction: {
              '@type': 'SearchAction'
            },
            target: {
              '@type': 'EntryPoint',
              urlTemplate: 'https://www.nawy.com'
            }
          })
        }}
      />

      <GlobalStyles />
      <NextNProgress
        color={theme.light.colors.primaryBlue}
        height={3}
        options={{ easing: 'ease', speed: 200 }}
        showOnShallow={false}
      />
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <SearchProvider filterOptions={JSON.parse(pageProps.filterOptions)}>
            <StyleSheetManager shouldForwardProp={prop => isPropValid(prop)}>
              <Layout {...pageProps}>
                <Component {...pageProps} />
              </Layout>
            </StyleSheetManager>
          </SearchProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Provider>
      {/*The container (DOM location) in which the react portal is injected */}
      <div id="portal" />
      <div>
        <meta itemProp="url" content="https://www.nawy.com/" />
      </div>
    </ThemeProvider>
  );
}

export default appWithTranslation(MyApp);
