type PropsType = {
  styles?: object;
  fill?: string;
  stroke?: string;
  hoverFill?: string;
};

const YoutubeIcon = ({ styles, fill, stroke, hoverFill }: PropsType) => {
  return (
    <svg
      className="youtubeSvg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      style={styles}
      xmlns="http://www.w3.org/2000/svg"
    >
      <style>
        {`.youtubeSvg:hover .fillPath {fill: ${hoverFill || '#1E4164'};}`}
      </style>
      <path
        className="fillPath"
        d="M14.9999 29.8113C23.0633 29.8113 29.5999 23.2747 29.5999 15.2113C29.5999 7.14797 23.0633 0.611328 14.9999 0.611328C6.93654 0.611328 0.399902 7.14797 0.399902 15.2113C0.399902 23.2747 6.93654 29.8113 14.9999 29.8113Z"
        fill={fill || '#1E4164'}
      />
      <path
        d="M22.7998 13.1113C22.7998 11.2113 21.1998 9.61133 19.2998 9.61133H10.2998C8.3998 9.61133 6.7998 11.2113 6.7998 13.1113V17.3113C6.7998 19.2113 8.3998 20.8113 10.2998 20.8113H19.2998C21.1998 20.8113 22.7998 19.2113 22.7998 17.3113V13.1113ZM17.4998 15.5113L13.4998 17.5113C13.2998 17.6113 12.7998 17.5113 12.7998 17.3113V13.2113C12.7998 13.0113 13.2998 12.9113 13.4998 13.0113L17.3998 15.1113C17.4998 15.2113 17.6998 15.4113 17.4998 15.5113Z"
        fill="white"
      />
    </svg>
  );
};
export default YoutubeIcon;
