type PropsType = {
  styles?: object;
  fill?: string;
  stroke?: string;
  hoverFill?: string;
};

const TwitterIcon = ({ styles, fill, stroke, hoverFill }: PropsType) => {
  return (
    <svg
      className="twitterSvg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      style={styles}
      xmlns="http://www.w3.org/2000/svg"
    >
      <style>
        {`.twitterSvg:hover .fillPath {fill: ${hoverFill || '#1E4164'};}`}
      </style>
      <path
        className="fillPath"
        d="M15.3998 29.8113C23.4632 29.8113 29.9998 23.2747 29.9998 15.2113C29.9998 7.14797 23.4632 0.611328 15.3998 0.611328C7.33645 0.611328 0.799805 7.14797 0.799805 15.2113C0.799805 23.2747 7.33645 29.8113 15.3998 29.8113Z"
        fill={fill || '#1E4164'}
      />
      <path
        d="M23.4998 10.2115C22.9998 10.4115 22.3998 10.6115 21.8998 10.7115C22.1998 10.7115 22.4998 10.2115 22.6998 10.0115C22.8998 9.7115 23.0998 9.4115 23.1998 9.0115V8.9115H23.0998C22.4998 9.2115 21.8998 9.5115 21.1998 9.6115C21.0998 9.6115 21.0998 9.6115 21.0998 9.6115C20.9998 9.5115 20.9998 9.5115 20.8998 9.4115C20.5998 9.2115 20.2998 9.0115 19.9998 8.8115C19.5998 8.6115 19.0998 8.5115 18.5998 8.6115C18.0998 8.6115 17.6998 8.8115 17.2998 9.0115C16.8998 9.2115 16.4998 9.5115 16.1998 9.9115C15.8998 10.3115 15.6998 10.7115 15.5998 11.2115C15.4998 11.7115 15.4998 12.1115 15.5998 12.6115C15.5998 12.7115 15.5998 12.7115 15.4998 12.7115C12.8998 12.3115 10.6998 11.4115 8.8998 9.4115C8.7998 9.3115 8.79981 9.3115 8.69981 9.4115C7.89981 10.6115 8.2998 12.4115 9.2998 13.3115C9.3998 13.4115 9.59981 13.5115 9.69981 13.7115C9.59981 13.7115 8.9998 13.6115 8.3998 13.3115C8.2998 13.3115 8.2998 13.3115 8.2998 13.4115C8.2998 13.5115 8.2998 13.6115 8.2998 13.8115C8.3998 15.0115 9.2998 16.1115 10.3998 16.5115C10.4998 16.6115 10.6998 16.6115 10.7998 16.6115C10.4998 16.7115 10.2998 16.7115 9.59981 16.6115C9.49981 16.6115 9.4998 16.6115 9.4998 16.7115C9.9998 18.2115 11.1998 18.6115 12.0998 18.9115C12.1998 18.9115 12.2998 18.9115 12.4998 18.9115C12.1998 19.3115 11.1998 19.7115 10.6998 19.8115C9.79981 20.1115 8.8998 20.2115 7.9998 20.1115C7.8998 20.1115 7.7998 20.1115 7.7998 20.1115V20.2115C7.9998 20.3115 8.1998 20.4115 8.3998 20.5115C8.9998 20.8115 9.59981 21.1115 10.1998 21.2115C13.4998 22.1115 17.0998 21.4115 19.5998 19.0115C21.4998 17.1115 22.1998 14.5115 22.1998 11.8115C22.1998 11.7115 22.2998 11.6115 22.3998 11.6115C22.8998 11.2115 23.2998 10.8115 23.6998 10.3115C23.4998 10.4115 23.4998 10.3115 23.4998 10.2115Z"
        fill="white"
      />
    </svg>
  );
};
export default TwitterIcon;
